import { GLAM_N_GO_API } from "../../apiSlice";

export const deleteBannerApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteBanner: builder.mutation({
      query: (data) => ({
        url: `/banners/delete/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BANNERS"],
    }),
  }),
});

export const { useDeleteBannerMutation } = deleteBannerApiSlice;
