import React from "react";
import { type SubCategory } from "interfaces";
import { useGetAllSubCategoriesQuery } from "store/api";
import {
  BreadCrumb,
  SUB_CATEGORIES_COLUMNS,
  GGButton,
  GGTable,
  Modal,
} from "components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteSubCategoryMutation } from "store/api/requests/subCategories/subCategoriesApiSlice";
import { getSortedData } from "utils";

const SubCategories = () => {
  const navigate = useNavigate();
  const { data, isFetching, refetch } =
    useGetAllSubCategoriesQuery("subCategories");
  const [deleteSubCategory, { isLoading }] = useDeleteSubCategoryMutation();

  const [modalOpen, setModalOpen] = React.useState(false);

  const categories = data?.subCategories;

  const sortedCategories = getSortedData(categories as any[], "createdAt");

  const onDeleteHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    const selectedCatId = searchParams.get("catId");
    try {
      await deleteSubCategory(selectedCatId as string)
        .unwrap()
        .then(async () => {
          await refetch();
        });
      navigate({
        search: "",
      });
      setModalOpen(false);
      toast.success("Sub Category deleted successfully");
    } catch (e) {
      toast.error("Failed to Sub Category");
    }
  };

  const cancelHandler = () => {
    setModalOpen(false);
    navigate({
      search: "",
    });
  };

  return (
    <div>
      <BreadCrumb />
      <div className="card">
        <GGTable
          data={sortedCategories ?? []}
          columns={SUB_CATEGORIES_COLUMNS}
          isLoading={isFetching}
          searchInputPlaceholder="Search categories..."
          onEditHandler={(selectedItem: SubCategory) => {
            navigate(selectedItem.docId);
          }}
          onAddNewHandler={() => {
            navigate("new");
          }}
          onDeleteHandler={(selectedItem: SubCategory) => {
            setModalOpen(true);
            const queryParams = new URLSearchParams();
            queryParams.set("catId", selectedItem.docId);
            navigate({
              search: `?${queryParams.toString()}`,
            });
          }}
          onRefresh={refetch}
        />
      </div>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        center
        body={
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold">Delete Sub Category</h3>
            <p className="text-sm">
              Are you sure you want to delete this sub category, This action is
              permanent and cannot be undone?
            </p>
            <div className="flex justify-end gap-2">
              <GGButton
                type="button"
                variant="outlined"
                onClick={cancelHandler}
              >
                Cancel
              </GGButton>
              <GGButton
                type="button"
                disable={isLoading}
                onClick={onDeleteHandler}
              >
                Delete
              </GGButton>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SubCategories;
