import { GLAM_N_GO_API } from "../../apiSlice";

export const addProductOnOrderApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    addProductOnOrder: builder.mutation({
      query: (order) => {
        return {
          url: `/orders/addProductToOrder/${order.id}`,
          method: "PATCH",
          body: order,
        };
      },
      invalidatesTags: ["ORDER"],
    }),
  }),
});

export const { useAddProductOnOrderMutation } = addProductOnOrderApiSlice;
