/* eslint-disable import/no-extraneous-dependencies */
import React from "react";

import Chart from "react-apexcharts";

const AreaChart = ({ type }: { type: string }) => {
  const options = {
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    bar: {
      borderRadius: 10,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "last",
    },
  };
  const series = [
    {
      name: "sales 1",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "sales 2",
      data: [23, 12, 54, 61, 32, 56, 81, 19],
    },
    {
      name: "sales 3",
      data: [24, 20, 5, 75, 42, 79, 72, 35],
    },
  ];

  return <Chart options={options} series={series} type={type ?? "area"} />;
};
export default AreaChart;
