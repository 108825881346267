import React, { useEffect, useState } from "react";
import { type ToastContent, toast } from "react-toastify";
import {
  BreadCrumb,
  Loader,
  OnBoardPreview,
  SectionTitle,
  Spinner,
} from "components/Common";
import { GGButton } from "components/UI";
import { Formik } from "formik";
import {
  useEditOnBoardingMutation,
  useGetAllOnBoardingScreensQuery,
  useLazyGetOnBoardingScreenQuery,
  useNewOnBoardingScreenMutation,
  useUploadImageMutation,
} from "store/api";

import { getCloudFlareImageUrl, getNextScreenOrder } from "utils";
import { useNavigate, useParams } from "react-router-dom";
import { type OnBoardingScreen } from "interfaces";

import OnBoardingScreenForm from "./OnBoardingScreenForm";
import { AddOnBoardScreenSchema } from "../schemas";

const AddOnBoardScreen = ({ isEdit }: { isEdit?: boolean }) => {
  const { screenId } = useParams();
  const navigate = useNavigate();
  const [uploadImage, { isLoading: uploadImageIsLoading }] =
    useUploadImageMutation();
  const [newOnBoardingScreen, { isLoading }] = useNewOnBoardingScreenMutation();
  const [editOnBoarding, { isLoading: isEditing }] =
    useEditOnBoardingMutation();

  const { data } = useGetAllOnBoardingScreensQuery("screens");

  const [getScreen, { isFetching }] = useLazyGetOnBoardingScreenQuery();

  const [screen, SetScreen] = useState<any>({});

  const fetchData = async () => {
    try {
      const fetchedData = await getScreen({ name: screenId }).unwrap();
      SetScreen(fetchedData.screen);
    } catch (error: any) {
      toast.error(error.message as ToastContent<unknown>);
    }
  };

  useEffect(() => {
    if (isEdit && screenId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, [isEdit, screenId]);

  const onSubmitHandler = async (values: any) => {
    if (isEdit) {
      if (values.image instanceof Blob && !values.imageLink) {
        const imageUploadRes = await uploadImage(values.image).unwrap();
        values.image = getCloudFlareImageUrl(imageUploadRes?.result);
      }
      const dataToSend = {
        [values.name]: {
          name: values.name,
          image: values.imageLink || values.image,
          title: values.title,
          description: values.description,
          color: values.color,
          order: values.order,
          hidden: false,
        },
      };
      await editOnBoarding(dataToSend).unwrap();
    } else {
      try {
        let image = "";
        if (!values.imageLink) {
          const res = await uploadImage(values?.image).unwrap();
          image = getCloudFlareImageUrl(res?.result);
        }
        const currentScreens = data?.screens as OnBoardingScreen;
        const nextOrder = getNextScreenOrder(currentScreens);

        const dataToSend = {
          [`ONBOARD_SCREEN_${nextOrder}`]: {
            name: `ONBOARD_SCREEN_${nextOrder}`,
            image: values.imageLink || image,
            title: values.title,
            description: values.description,
            color: values.color,
            order: nextOrder,
            hidden: false,
          },
        };

        await newOnBoardingScreen(dataToSend).unwrap();
        toast.success("Screen added successfully");
        navigate("../onboarding-screen");
      } catch (error: any) {
        toast.error(error.message as ToastContent<unknown>);
      }
    }
  };

  return (
    <div>
      <BreadCrumb />
      <div className="card">
        {isFetching && isEdit ? (
          <Loader />
        ) : (
          <>
            <SectionTitle title="Add OnBoarding Screen" />
            <Formik
              enableReinitialize
              initialValues={{
                name: screen?.name || "",
                order: screen?.order || "",
                title: screen?.title || "",
                description: screen?.description || "",
                color: screen?.color || "",
                image: screen?.image || null,
                imageLink: null,
              }}
              validationSchema={AddOnBoardScreenSchema}
              onSubmit={async (values, { resetForm }) => {
                await onSubmitHandler(values);
                resetForm();
                navigate("../onboarding-screen");
              }}
            >
              {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <form onSubmit={handleSubmit}>
                    <OnBoardingScreenForm
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                    <div className="flex justify-center">
                      <GGButton
                        type="submit"
                        disable={isLoading || !dirty || !isValid}
                      >
                        {isLoading || uploadImageIsLoading || isEditing ? (
                          <Spinner />
                        ) : isEdit ? (
                          "Edit Screen"
                        ) : (
                          "Add Screen"
                        )}
                      </GGButton>
                    </div>
                  </form>
                  <div className="flex justify-center flex-col items-center">
                    <h4 className="font-semibold text-md mb-2 text-center">
                      App Preview
                    </h4>
                    <OnBoardPreview
                      title={values.title ?? "Title"}
                      description={
                        values.description ??
                        "description is too long to fit in this space so we are going to truncate it to fit in this space."
                      }
                      image={
                        values?.image !== null
                          ? values.image instanceof Blob
                            ? URL.createObjectURL(
                                values?.image as Blob | MediaSource,
                              )
                            : values.imageLink || values?.image
                          : "https://via.placeholder.com/150"
                      }
                      showActions={false}
                      bgColor={values.color}
                    />
                  </div>
                </div>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default AddOnBoardScreen;
