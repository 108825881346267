import { GLAM_N_GO_API } from "../../apiSlice";

export const resetPasswordApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/resetPassword/${data.token}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["USERS"],
    }),
  }),
});

export const { useResetPasswordMutation } = resetPasswordApiSlice;
