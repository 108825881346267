import { GLAM_N_GO_API } from "../../apiSlice";

export const getApprovedApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getApproved: builder.query({
      query: () => ({
        url: "/stores/getAllApprovedStores",
        providesTags: ["SHOPS", "SHOP"],
      }),
    }),
  }),
});

export const { useGetApprovedQuery } = getApprovedApi;
