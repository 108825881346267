import React from "react";
import { RegisterForm } from "components";

const AddNewUser = () => {
  return (
    <div className="card">
      <RegisterForm isNew />
    </div>
  );
};

export default AddNewUser;
