import { GLAM_N_GO_API } from "../../apiSlice";

export const getUsersApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: "/auth/getAllUsers",
        providesTags: ["USERS"],
      }),
    }),
  }),
});

export const { useGetUsersQuery } = getUsersApi;
