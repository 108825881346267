import { GLAM_N_GO_API } from "../../apiSlice";

export const contactAdminApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    contactAdmin: builder.mutation({
      query: (data) => {
        return {
          url: "/auth/contactAdmin",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useContactAdminMutation } = contactAdminApiSlice;
