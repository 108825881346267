import { GLAM_N_GO_API } from "../../apiSlice";

export const deleteFaqApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteFaq: builder.mutation({
      query: (data) => ({
        url: `/FAQs/remove/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FAQS"],
    }),
  }),
});

export const { useDeleteFaqMutation } = deleteFaqApiSlice;
