import { GLAM_N_GO_API } from "../../apiSlice";

export const getFaqApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getFaq: builder.query({
      query: (data) => ({
        url: `/FAQs/getFAQ/${data.id}`,
        providesTags: ["FAQS"],
      }),
    }),
  }),
});

export const { useLazyGetFaqQuery } = getFaqApi;
