import { GLAM_N_GO_API } from "../../apiSlice";

export const getSubCategoriesByCategoryIdApiSlice =
  GLAM_N_GO_API.injectEndpoints({
    endpoints: (builder) => ({
      getSubCategoriesByCategoryId: builder.query({
        query: (data) => ({
          url: `/subCategories/getByCategoryId/${data.id}`,
          providesTags: ["CATEGORIES"],
        }),
      }),
    }),
  });

export const { useGetSubCategoriesByCategoryIdQuery } =
  getSubCategoriesByCategoryIdApiSlice;
