import React, { type FC } from "react";
import { type FormComponentProps } from "interfaces";
import { FormControl } from "components/UI";
import { Input } from "components/Common";

const FaqForm: FC<FormComponentProps> = ({ values }) => {
  return (
    <div>
      {" "}
      <FormControl>
        <Input
          name="question"
          type="text"
          label="Question"
          value={values.question}
          note="required"
        />
      </FormControl>
      <FormControl>
        <Input
          name="answer"
          type="textarea"
          label="Answer"
          value={values.answer}
          note="required"
        />
      </FormControl>
    </div>
  );
};

export default FaqForm;
