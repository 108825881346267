import { GLAM_N_GO_API } from "../../apiSlice";

export const editOrderProductApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editOrderProduct: builder.mutation({
      query: (order) => {
        return {
          url: `/orders/editOrderProduct/${order.id}`,
          method: "PATCH",
          body: order,
        };
      },
      invalidatesTags: ["ORDER"],
    }),
  }),
});

export const { useEditOrderProductMutation } = editOrderProductApiSlice;
