import { GLAM_N_GO_API } from "../../apiSlice";

export const approveShopApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    approveShop: builder.mutation({
      query: (Shop) => {
        return {
          url: "/stores/approveStore",
          method: "POST",
          body: {
            id: Shop.id,
          },
        };
      },
      invalidatesTags: ["SHOP", "SHOPS"],
    }),
  }),
});

export const { useApproveShopMutation } = approveShopApiSlice;
