import { GLAM_N_GO_API } from "../../apiSlice";

export const deleteShopApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteShop: builder.mutation({
      query: (Shop) => {
        return {
          url: `/stores/delete/${Shop.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["SHOPS", "SHOP"],
    }),
  }),
});

export const { useDeleteShopMutation } = deleteShopApiSlice;
