import React, { useState, useEffect } from "react";
import { type FirebaseDate } from "interfaces";
import { getAgoFormattedDate } from "utils";

const LiveTimeDisplay = ({ approvedAt }: { approvedAt: FirebaseDate }) => {
  const [formattedTime, setFormattedTime] = useState(
    getAgoFormattedDate(approvedAt),
  );

  const timeInterval = formattedTime.includes("second") ? 1000 : 60000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormattedTime(getAgoFormattedDate(approvedAt));
    }, timeInterval); // Update every second

    return () => {
      clearInterval(intervalId);
    }; // Cleanup on unmount
  }, [approvedAt]);

  return <span className="font-bold">{formattedTime}</span>;
};

export default LiveTimeDisplay;
