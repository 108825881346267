import React from "react";
import { BreadCrumb, Loader } from "components";
import { FaPlus } from "react-icons/fa";
import BannerCard from "components/BannerCard";
import { useNavigate } from "react-router-dom";
import { useGetAllBannersQuery } from "store/api";
import { type BannerProps } from "interfaces";

const Banners = () => {
  const navigate = useNavigate();

  const { data, isFetching } = useGetAllBannersQuery("banners");

  const banners = data?.banners;

  return (
    <div>
      <BreadCrumb />
      <div>
        <div className="flex justify-end my-3">
          <button
            onClick={() => {
              navigate("add-banner");
            }}
            className=" bg-primary text-white flex justify-center items-center rounded-full px-4 py-2 text-sm font-semibold mt-2 hover:bg-primary-shade transition-all duration-300
            "
          >
            <FaPlus />
            Add New
          </button>
        </div>
        {isFetching ? (
          <Loader />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {banners?.map((banner: BannerProps) => (
              <BannerCard key={banner.docId} banner={banner} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Banners;
