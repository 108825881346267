import { GLAM_N_GO_API } from "../../apiSlice";

export const getStoreOrdersApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getStoreOrders: builder.query({
      query: (data) => ({
        url: `/orders/getStoreOrders/${data.id}`,
        providesTags: ["ORDERS"],
      }),
    }),
  }),
});

export const { useGetStoreOrdersQuery } = getStoreOrdersApi;
