import React from "react";
import SHOP from "../../../../../assets/images/shop1.png";
import { FaClock } from "react-icons/fa";

export const NameColumn = ({
  name,
  email,
}: {
  name: string;
  email: string;
}) => (
  <div className="flex gap-1 items-center">
    <div className="w-[40px] h-[40px] rounded mr-2">
      <img src={SHOP} className="rounded" />
    </div>
    <div>
      <h6 className="truncate max-w-[120px] xs:max-w-[180px] font-poppins font-medium ">
        {name}
      </h6>
      <a
        href="mailto:me@gmail.com"
        className="text-primary font-poppins font-medium"
      >
        {email}
      </a>
    </div>
  </div>
);

export const DateColumn = ({
  name,
  email,
}: {
  name: string;
  email: string;
}) => (
  <div className="flex justify-start items-center gap-2">
    <FaClock className="text-linear" />
    <div>
      <h6 className="whitespace-nowrap">{name}</h6>
      <span className="whitespace-nowrap">{email}</span>
    </div>
  </div>
);
