import { GLAM_N_GO_API } from "../../apiSlice";

export const getAllShopsApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getAllShops: builder.query({
      query: () => ({
        url: "/stores/getAll",
        providesTags: ["SHOPS", "SHOP"],
      }),
    }),
  }),
});

export const { useGetAllShopsQuery, useLazyGetAllShopsQuery } = getAllShopsApi;
