import { GLAM_N_GO_API } from "../../apiSlice";

export const getCategoryApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query({
      query: (data) => ({
        url: `/categories/${data.id}`,
        providesTags: ["CATEGORIES"],
      }),
    }),
  }),
});

export const { useGetCategoryQuery, useLazyGetCategoryQuery } =
  getCategoryApiSlice;
