import { GLAM_N_GO_API } from "../../apiSlice";

export const editOrderApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editOrder: builder.mutation({
      query: (order) => {
        return {
          url: `/orders/update/${order.id}`,
          method: "PATCH",
          body: order,
        };
      },
      invalidatesTags: ["ORDER"],
    }),
  }),
});

export const { useEditOrderMutation } = editOrderApiSlice;
