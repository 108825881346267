import React, { type FC } from "react";
import { type FormComponentProps } from "interfaces";
import { FormControl } from "components/UI";
import { ImageUpload, Input } from "components/Common";
import { ONBOARDING_SCREENS_COLORS } from "../../../constants";

const OnBoardingScreenForm: FC<FormComponentProps> = ({
  values,
  setFieldValue,
}) => {
  return (
    <div>
      <FormControl>
        <ImageUpload
          fieldName="image"
          setFieldValue={setFieldValue}
          imageUrl={values.image}
          linkName="imageLink"
        />
      </FormControl>
      <FormControl>
        <Input type="text" name="title" label="Title" note="required" />
      </FormControl>
      <FormControl>
        <Input
          type="textarea"
          name="description"
          label="Description"
          note="required"
        />
      </FormControl>
      <FormControl>
        <Input
          type="select"
          label="Screen Color"
          name="color"
          options={ONBOARDING_SCREENS_COLORS.map((color: string, idx) => ({
            value: color,
            label: `Screen Color ${idx + 1}`,
          }))}
        />
      </FormControl>
    </div>
  );
};

export default OnBoardingScreenForm;
