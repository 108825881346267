import React from "react";
import { Formik } from "formik";
import { Input } from "components/Common";
import { GGButton } from "components/UI";
import { useSelector } from "react-redux";
import { selectUser } from "store/slices";
import { useContactAdminMutation } from "store/api";
import { toast } from "react-toastify";

const HelpForm = () => {
  const user = useSelector(selectUser);
  const [contactAdmin, { isLoading }] = useContactAdminMutation();
  const userName = `${user.firstName} ${user.lastName}`;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: userName || "",
          email: user.email || "",
          message: "",
        }}
        onSubmit={async (values) => {
          await contactAdmin(values);
          values.message = "";
          toast.success("Message sent successfully");
        }}
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={values.name}
            />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={values.email}
            />
            <Input
              type="textarea"
              name="message"
              placeholder="Message"
              value={values.message}
              rows={5}
            />
            <GGButton type="submit" loading={isLoading}>
              Send Message
            </GGButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default HelpForm;
