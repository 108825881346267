import { GLAM_N_GO_API } from "../../apiSlice";

export const deactivateShopApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deactivateShop: builder.mutation({
      query: (store) => {
        return {
          url: "/stores/deactivateStore",
          method: "POST",
          body: {
            id: store.id,
            deactivatedReason: store.deactivatedReason,
          },
        };
      },
      invalidatesTags: ["SHOPS", "SHOP"],
    }),
  }),
});

export const { useDeactivateShopMutation } = deactivateShopApiSlice;
