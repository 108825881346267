import React, { useEffect, useState } from "react";
import { BreadCrumb, GGTable, PRODUCT_COLUMNS } from "components";
import { useNavigate } from "react-router-dom";
import { useGetAllCategoriesQuery, useGetProductsQuery } from "store/api";
import { useLazyGetMyStoreQuery } from "store/api/requests/shops/getMyStoreApiSlice";
import { UserRoles } from "interfaces";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getSortedData } from "utils";

const Products = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const [getStore] = useLazyGetMyStoreQuery();
  const { data, isFetching, refetch } = useGetProductsQuery("products");
  const [isVerified, setIsVerified] = useState(true);
  const { data: categoriesData, isFetching: categoriesLoading } =
    useGetAllCategoriesQuery("categories");

  const products = data?.products.filter(
    (product: any) => product.storeId !== undefined,
  );

  const formattedData = products?.map((pdt: any) => {
    const category = categoriesData?.categories?.find(
      (cat: any) => cat.docId === pdt.category,
    );
    return {
      ...pdt,
      category: category?.name,
    };
  });

  const sortedProducts = getSortedData(formattedData as any[], "createdAt");

  useEffect(() => {
    if (user?.role === UserRoles.Vendor) {
      getStore("myStore")
        .unwrap()
        .then((store) => {
          setIsVerified((store?.stores[0]?.isVerified as boolean) ?? false);
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  }, [user]);

  return (
    <>
      <BreadCrumb />
      <div className="card">
        <GGTable
          data={sortedProducts ?? []}
          columns={PRODUCT_COLUMNS}
          isLoading={isFetching || categoriesLoading}
          searchInputPlaceholder="Search Products"
          onEditHandler={(selectedItem) => {
            navigate(`../products/${selectedItem.docId}`);
          }}
          onViewHandler={(selectedItem) => {
            navigate(`../products/${selectedItem.docId}`);
          }}
          onAddNewHandler={() => {
            if (user?.role === UserRoles.Vendor && !isVerified) {
              toast.info(
                "You can not add products until your store is verified by admin.",
              );
              return;
            }
            navigate("./new");
          }}
          showExportButton
          onRefresh={refetch}
        />
      </div>
    </>
  );
};
export default Products;
