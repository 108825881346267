import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GGTable,
  BreadCrumb,
  CATEGORIES_COLUMNS,
  Modal,
  GGButton,
} from "components";
import { useDeleteCategoryMutation, useGetAllCategoriesQuery } from "store/api";
import { toast } from "react-toastify";
import { getSortedData } from "utils";

const ProductCategories = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<any>("");
  const [deleteCategory, { isLoading }] = useDeleteCategoryMutation();
  const navigate = useNavigate();
  const { data, isFetching, refetch } = useGetAllCategoriesQuery("categories");

  const categories = data?.categories;
  const sortedCategories = getSortedData(categories as any[], "createdAt");

  const onDeleteHandler = async () => {
    try {
      await deleteCategory(recordToDelete).unwrap();
      setModalOpen(false);
      toast.success("Category deleted successfully");
    } catch (error: any) {
      toast.error("An error occurred while deleting category");
    }
  };

  return (
    <>
      <div>
        <BreadCrumb />
        <div className="card">
          <div>
            <GGTable
              data={sortedCategories ?? []}
              columns={CATEGORIES_COLUMNS}
              isLoading={isFetching}
              searchInputPlaceholder="Search categories..."
              onEditHandler={(selectedItem) => {
                navigate(`../product-categories/${selectedItem.docId}`);
              }}
              onDeleteHandler={(selectedItem) => {
                setRecordToDelete(selectedItem.docId);
                setModalOpen(true);
              }}
              onAddNewHandler={() => {
                navigate("./new");
              }}
              onRefresh={refetch}
            />
          </div>
          <Modal
            open={modalOpen}
            setOpen={setModalOpen}
            center
            body={
              <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold">
                  Delete Category Confirmation
                </h3>
                <p className="text-sm">
                  Are you sure you want to delete this category?, This action is
                  permanent and cannot be undone.
                </p>
                <div className="flex justify-end gap-2">
                  <GGButton
                    type="button"
                    variant="outlined"
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </GGButton>
                  <GGButton
                    type="button"
                    onClick={onDeleteHandler}
                    loading={isLoading}
                  >
                    Yes, Delete
                  </GGButton>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default ProductCategories;
