import { GLAM_N_GO_API } from "../../apiSlice";

export const getSocialsApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getSocials: builder.query({
      query: () => ({
        url: "/socials/getAll",
        providesTags: ["SOCIALS"],
      }),
    }),
  }),
});

export const { useGetSocialsQuery } = getSocialsApi;
