import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { GLAM_N_GO_API } from "./api/apiSlice";
import authReducer from "./slices/authSlice";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Choose your storage engine

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  [GLAM_N_GO_API.reducerPath]: GLAM_N_GO_API.reducer,
  auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(GLAM_N_GO_API.middleware),

  devTools: true, // false for production
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
