import React, { useEffect } from "react";
import { BreadCrumb, Loader } from "components/Common";
import { Formik } from "formik";

import SubCategoryForm from "./SubCategoryForm";
import { GGButton } from "components/UI";
import {
  useAddSubCategoryMutation,
  useLazyGetSubCategoryByIdQuery,
  useUpdateSubCategoryMutation,
  useUploadImageMutation,
} from "store/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getCloudFlareImageUrl } from "utils";

const AddSubCategory = ({ isEdit }: { isEdit?: boolean }) => {
  const { catId } = useParams();
  const [addNewSubCategory, { isLoading }] = useAddSubCategoryMutation();
  const [updateSubCategory, { isLoading: isEditing }] =
    useUpdateSubCategoryMutation();
  const [uploadImage, { isLoading: uploadImageIsLoading }] =
    useUploadImageMutation();
  const navigate = useNavigate();
  const [subCategory, setSubCategory] = React.useState({} as any);

  const [getSubCategory, { isFetching }] = useLazyGetSubCategoryByIdQuery();

  const fetchData = async () => {
    const fetchedData = await getSubCategory(catId).unwrap();
    setSubCategory(fetchedData.subCategory);
  };

  useEffect(() => {
    if (isEdit && catId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, [isEdit, catId]);

  const submitHandler = async (values: any) => {
    if (isEdit) {
      if (values.image instanceof Blob && !values.imageLink) {
        const imageUploadRes = await uploadImage(values.image).unwrap();
        values.image = getCloudFlareImageUrl(imageUploadRes?.result);
      }
      await updateSubCategory({
        id: catId,
        data: {
          category_id: values.category,
          name: values.name,
          image: values.imageLink || values.image,
          description: values.description,
          isHidden: values.isHidden,
        },
      }).unwrap();
      toast.success("Sub category updated successfully");
    } else {
      try {
        if (!values.imageLink) {
          const imageUploadRes = await uploadImage(values.image).unwrap();
          values.image = getCloudFlareImageUrl(imageUploadRes?.result);
        }

        await addNewSubCategory({
          category_id: values.category,
          name: values.name,
          image: values.imageLink || values.image,
          description: values.description,
          isHidden: true,
        }).unwrap();
        toast.success("Sub category added successfully");
      } catch (error) {
        toast.success("Failed to add sub category");
      }
    }
  };

  return (
    <div>
      <BreadCrumb lastName={subCategory?.name} />
      <div className="card">
        {isFetching ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              name: subCategory?.name || "",
              description: subCategory?.description || "",
              image: subCategory?.image || null,
              category: subCategory?.category_id || "",
              isHidden: subCategory?.isHidden || false,
              imageLink: null,
            }}
            onSubmit={async (values) => {
              await submitHandler(values);
              navigate("../sub-categories");
            }}
          >
            {({ values, setFieldValue, handleSubmit, dirty, isValid }) => (
              <form onSubmit={handleSubmit}>
                <SubCategoryForm
                  values={values}
                  setFieldValue={setFieldValue}
                  isEdit={isEdit}
                />
                <div className="my-3 flex justify-end">
                  <GGButton
                    type="submit"
                    disable={
                      isLoading ||
                      uploadImageIsLoading ||
                      isEditing ||
                      !dirty ||
                      !isValid
                    }
                    loading={isLoading || uploadImageIsLoading || isEditing}
                  >
                    {isEdit ? "Save Changes" : "Add Sub Category"}
                  </GGButton>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AddSubCategory;
