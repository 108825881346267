import { GLAM_N_GO_API } from "../../apiSlice";

export const getAllOnBoardingScreensApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getAllOnBoardingScreens: builder.query({
      query: () => ({
        url: "/FAQs/getAllScreens",
        providesTags: ["SCREENS"],
      }),
    }),
  }),
});

export const { useGetAllOnBoardingScreensQuery } =
  getAllOnBoardingScreensApiSlice;
