import React, { type FC, useCallback, useRef, useState } from "react";
import { type Accept, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Button from "../Button";
import { formatBytes } from "utils";

const Dropzone: FC<{
  onDrop: (files: File[]) => void;
  className?: string;
  label?: any;
  accept?: Accept;
  multiple?: boolean;
  maxSize?: number;
  hideFiles?: boolean;
}> = ({ onDrop, className, accept, multiple, label, hideFiles, maxSize }) => {
  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChooseFile = (e: any) => {
    e.stopPropagation();
    inputRef.current?.click();
  };

  const onDropCallback = useCallback((acceptedFiles: File[]) => {
    onDrop(acceptedFiles);
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
    onDropRejected(fileRejections: any) {
      const fileRejection = fileRejections[0];
      if (fileRejection.errors[0].code === "file-too-large") {
        toast.error("File is too large");
      } else {
        toast.error("Please select allowed file types");
      }
      onDrop([]);
      setFiles([]);
    },
    multiple,
    accept,
    maxSize,
  });

  const acceptTypesString = accept
    ? Object.keys(accept)
        .map((key) => accept[key])
        .flat()
        .toString()
        .replaceAll(",", ", ")
    : "";
  const fileNames = files.map((file) => file.name).join(", ");

  return (
    <div
      {...getRootProps()}
      className={`w-full border-dashed border-2 border-primary/10 p-4 rounded-md justify-center items-center flex flex-col gap-2 bg-[#f0f0f5] cursor-pointer hover:border-primary/50 ${isDragActive ? "border-primary/50" : ""} ${className || ""}`}
    >
      <input
        {...getInputProps()}
        ref={inputRef}
        type="file"
        className="hidden"
      />
      {label &&
        (typeof label === "string" ? <p className="label">{label}</p> : label)}
      {files.length > 0 && multiple && !hideFiles && (
        <p className="sublabel">
          {files.length} file{files.length === 1 ? "" : "s"} selected
        </p>
      )}
      {fileNames && !hideFiles && (
        <p className="sublabel  break-all">{fileNames}</p>
      )}
      <Button variant="secondary" onClick={onChooseFile} type="button">
        {multiple ? "Select Images" : "Select Image"}
      </Button>
      {(acceptTypesString || maxSize) && (
        <p>
          {acceptTypesString ? `${acceptTypesString}` : ""}
          {maxSize ? `, max. ${formatBytes(maxSize)}` : ""}
        </p>
      )}
    </div>
  );
};

export default Dropzone;
