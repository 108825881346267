import { GLAM_N_GO_API } from "../../apiSlice";

export const newBannerApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    newBanner: builder.mutation({
      query: (data) => {
        return {
          url: "/banners/create",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["BANNERS"],
    }),
  }),
});

export const { useNewBannerMutation } = newBannerApiSlice;
