import React from "react";
import { type ToastContent, toast } from "react-toastify";
import { GGButton } from "components/UI";
import { BreadCrumb, Loader, SectionTitle, Spinner } from "components/Common";
import {
  useEditFaqMutation,
  useLazyGetFaqQuery,
  useNewFaqMutation,
} from "store/api";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import FaqForm from "./FaqForm";

const AddFaq = ({ isEdit }: { isEdit?: boolean }) => {
  const { faqId } = useParams();
  const navigate = useNavigate();
  const [newFaq, { isLoading }] = useNewFaqMutation();
  const [editFaq, { isLoading: isEditing }] = useEditFaqMutation();
  const [faq, setFaq] = React.useState<any>({});
  const [getFaq, { isLoading: isFaqLoading }] = useLazyGetFaqQuery();

  const fetchData = async () => {
    try {
      const data = await getFaq({ id: faqId }).unwrap();
      setFaq(data?.Faq);
    } catch (error: any) {
      toast.error(error.message as ToastContent<unknown>);
    }
  };

  React.useEffect(() => {
    if (isEdit && faqId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, [isEdit]);

  const onSubmitHandler = async (values: any) => {
    if (isEdit) {
      try {
        await editFaq({
          FAQ: {
            question: values.question,
            answer: values.answer,
          },
          id: faqId,
        }).unwrap();
        toast.success("FAQ updated successfully");
      } catch (error: any) {
        toast.error(error.message as ToastContent<unknown>);
      }
    } else {
      try {
        await newFaq({
          FAQ: {
            question: values.question,
            answer: values.answer,
          },
        }).unwrap();
        toast.success("FAQ added successfully");
      } catch (error: any) {
        toast.error(error.message as ToastContent<unknown>);
      }
    }
  };

  return (
    <div>
      <BreadCrumb lastName={faq?.FAQ?.question} />
      <div className="card">
        {isFaqLoading ? (
          <Loader />
        ) : (
          <>
            <SectionTitle title="Add FAQ" />
            <Formik
              enableReinitialize
              //   validationSchema={SocialSchema}
              initialValues={{
                question: faq?.FAQ?.question || "",
                answer: faq?.FAQ?.answer || "",
              }}
              onSubmit={async (values, { resetForm }) => {
                await onSubmitHandler(values);
                navigate("../faqs");
                resetForm();
              }}
            >
              {({ handleSubmit, values, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <FaqForm values={values} />

                  <GGButton
                    type="submit"
                    disable={isLoading || isEditing || !dirty || !isValid}
                  >
                    {isLoading ? <Spinner /> : isEdit ? "Edit FAQ" : "Add FAQ"}
                  </GGButton>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default AddFaq;
