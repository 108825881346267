import { GLAM_N_GO_API } from "../../apiSlice";

export const DeleteProductApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteProduct: builder.mutation({
      query: (product) => ({
        url: `/products/delete/${product.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PRODUCTS"],
    }),
  }),
});

export const { useDeleteProductMutation } = DeleteProductApiSlice;
