import { GLAM_N_GO_API } from "../../apiSlice";

export const getMyStoreApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getMyStore: builder.query({
      query: () => ({
        url: "/stores/getMyStores",
        providesTags: ["SHOPS", "SHOP"],
      }),
    }),
  }),
});

export const { useGetMyStoreQuery, useLazyGetMyStoreQuery } =
  getMyStoreApiSlice;
