import React from "react";
import { Loader, StaticPageTemplate } from "components";
import { useGetStaticPagesQuery } from "store/api";

const PrivacyPolicy = () => {
  const { data, isFetching } = useGetStaticPagesQuery("staticPages");
  const page = data?.pages.find(
    (item: any) => item.pageId === "PRIVACY_POLICY_PAGE",
  );

  return (
    <div>
      {isFetching ? <Loader showText /> : <StaticPageTemplate page={page} />}
    </div>
  );
};

export default PrivacyPolicy;
