import { GLAM_N_GO_API } from "../../apiSlice";

export const DeleteCategoryApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteCategory: builder.mutation({
      query: (catId) => ({
        url: `/categories/delete/${catId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CATEGORIES"],
    }),
  }),
});

export const { useDeleteCategoryMutation } = DeleteCategoryApiSlice;
