import { GLAM_N_GO_API } from "../../apiSlice";

export const getOnBoardingScreenApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getOnBoardingScreen: builder.query({
      query: (data) => ({
        url: `/FAQs/getScreen/${data?.name}`,
        providesTags: ["SCREENS"],
      }),
    }),
  }),
});

export const { useGetOnBoardingScreenQuery, useLazyGetOnBoardingScreenQuery } =
  getOnBoardingScreenApiSlice;
