import { BreadCrumb, DeleteDialog, GGTable, SHOPS_COLUMN } from "components";
import { UserRoles } from "interfaces";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteShopMutation, useGetAllShopsQuery } from "store/api";
import { getSortedData } from "utils";

const Stores = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);
  const { data, isFetching, refetch } = useGetAllShopsQuery("shops");
  const storesData = data?.stores;

  const formattedData = storesData?.filter(
    (shop: any) => shop.name !== undefined,
  );

  const sortedData = getSortedData(formattedData as any[], "createdAt");

  const [onDelete, setOnDelete] = useState(false);
  const [deleteShop, { isLoading }] = useDeleteShopMutation();

  if (user?.role === UserRoles.Vendor && formattedData?.length === 1) {
    navigate(`../my-store`);
    return null;
  }

  const onDeleteHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    const selectedShopId = searchParams.get("storeId");
    try {
      await deleteShop({ id: selectedShopId as string })
        .unwrap()
        .then(async () => {
          await refetch();
        });
      navigate({
        search: "",
      });
      setOnDelete(false);
      toast.success("Store deleted successfully");
    } catch (e) {
      toast.error("Failed to delete store, Please try again");
    }
  };

  const cancelHandler = () => {
    setOnDelete(false);
    navigate({
      search: "",
    });
  };

  return (
    <div>
      <BreadCrumb />
      <div className="card">
        <div className="w-full mt-8">
          <DeleteDialog
            show={onDelete}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onConfirm={onDeleteHandler}
            onCancel={cancelHandler}
            isLoading={isLoading}
            itemName="store"
            actionName="Delete"
          />
          <GGTable
            data={sortedData ?? []}
            columns={SHOPS_COLUMN}
            isLoading={isFetching}
            searchInputPlaceholder="Search store..."
            showExportButton
            onAddNewHandler={() => {
              navigate("./new");
            }}
            onDeleteHandler={(selectedItem) => {
              setOnDelete(true);
              const queryParams = new URLSearchParams();
              queryParams.set("storeId", selectedItem.docId as string);

              navigate({
                search: `?${queryParams.toString()}`,
              });
            }}
            onEditHandler={(selectedItem) => {
              navigate(`../stores/${selectedItem.docId}/edit`);
            }}
            onViewHandler={(selectedItem) => {
              navigate(`../stores/${selectedItem.docId}`);
            }}
            onRefresh={refetch}
          />
        </div>
      </div>
    </div>
  );
};

export default Stores;
