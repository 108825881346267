import { GLAM_N_GO_API } from "store/api/apiSlice";

export const uploadImageApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: (image) => {
        const formData = new FormData();
        formData.append("file", image as Blob);

        return {
          url: "/files/uploadFile",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["SOCIALS"],
    }),
    getFiles: builder.query({ query: () => "/files/getAllFilesFromCDN" }),
    checkFileStatus: builder.mutation({
      query: (data) => {
        return {
          url: "/files/checkFileStatus",
          method: "POST",
          body: { url: data },
        };
      },
    }),
  }),
});

export const {
  useUploadImageMutation,
  useGetFilesQuery,
  useLazyGetFilesQuery,
  useCheckFileStatusMutation,
} = uploadImageApiSlice;
