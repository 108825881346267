import { GLAM_N_GO_API } from "../../apiSlice";

export const deleteOnBoardingScreenApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteOnBoardingScreen: builder.mutation({
      query: (data) => {
        return {
          url: "/FAQs/deleteScreen",
          method: "DELETE",
          body: data,
        };
      },
      invalidatesTags: ["SCREENS"],
    }),
  }),
});

export const { useDeleteOnBoardingScreenMutation } =
  deleteOnBoardingScreenApiSlice;
