import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = ({
  value,
  placeholder,
  onChange,
}: {
  value: string;
  placeholder: string;
  onChange: any;
}) => {
  return (
    <div>
      <ReactQuill
        modules={{
          toolbar: [
            [
              { header: "1" },
              { header: "2" },
              { header: "3" },
              { header: "4" },
              { font: ["Poppins"] },
            ],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image"],
            ["clean"],
          ],
        }}
        theme="snow"
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? "Write here..."}
      />
    </div>
  );
};

export default RichTextEditor;
