import { GLAM_N_GO_API } from "../../apiSlice";

export const deleteSocialApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteSocial: builder.mutation({
      query: (data) => ({
        url: `/socials/delete/${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SOCIALS"],
    }),
  }),
});

export const { useDeleteSocialMutation } = deleteSocialApiSlice;
