import React, { useState } from "react";
import { Header, Sidebar } from "components";
import { CURRENT_VERSION } from "../../constants";
import { Outlet } from "react-router-dom";
import { MdWarningAmber } from "react-icons/md";

const Dashboard = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="w-full flex items-start gap-10 h-screen bg-[#f7f7f7] fixed">
      <div
        className={`bg-[#000]/50 w-full h-screen absolute z-50 md:hidden max-md:${showMenu ? "" : "hidden"}`}
        onClick={() => {
          setShowMenu(false);
        }}
      ></div>
      <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="w-full overflow-y-auto pl-9 max-xl:p-0 md:ml-[250px]">
        <Header setShowMenu={setShowMenu} />
        <div className="w-full max-h-screen overflow-y-auto p-3 mt-[70px]">
          {window.location.hostname !== "glamngo.ug" && (
            <div className="bg-[#feecdc] text-[#b43403] p-4 rounded-lg shadow-md my-3">
              <div className="flex gap-3 flex-col md:flex-row justify-center md:justify-start items-center">
                <MdWarningAmber size={60} />

                <div className="text-center md:text-left">
                  <h5 className="font-semibold">*Warning*</h5>
                  <p>
                    You&apos;re using the development version of the GnG
                    Dashboard, Anything created here will not be visible to the
                    vendors dashboard or client application but can be used for
                    demo/testing purposes
                  </p>
                </div>
              </div>
            </div>
          )}
          <Outlet />
          <div className="mt-12">
            <div className="flex justify-center text-sm mt-4 text-primary font-semibold">
              v{CURRENT_VERSION}
            </div>
            <div className="text-center text-sm text-gray-400 p-2">
              Designed, Developed and Maintained by{" "}
              <a
                href="https://moxtechdevelopers.com"
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                MOXTECH DEVELOPERS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
