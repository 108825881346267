import { DeleteDialog } from "components/Common";
import React, { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteFaqMutation } from "store/api";

const FaqCard = ({
  id,
  question,
  answer,
}: {
  id: string;
  question: string;
  answer: string;
}) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const navigate = useNavigate();
  const [deleteFaq, { isLoading: isDeleting }] = useDeleteFaqMutation();

  const onDeleteHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    const selectedBanner = searchParams.get("faqId");
    try {
      await deleteFaq({ id: selectedBanner as string }).unwrap();

      navigate({
        search: "",
      });
      setOnDelete(false);
      toast.success("Screen deleted successfully");
    } catch (e) {
      toast.error("Failed to delete screen");
    }
  };

  const cancelHandler = () => {
    setOnDelete(false);
    navigate({
      search: "",
    });
  };

  return (
    <>
      <DeleteDialog
        show={onDelete}
        itemName="FAQ"
        actionName="Delete"
        onCancel={cancelHandler}
        onConfirm={onDeleteHandler}
        isLoading={isDeleting}
      />
      <div className="group relative flex flex-col justify-start bg-white py-0 px-2 border border-black/50 rounded-md my-2">
        <div className="w-full ">
          <button
            onClick={() => {
              setShowAnswer(!showAnswer);
            }}
            className="w-full text-primary flex items-center border-b border-b-black/40 justify-between px-2 py-3 text-sm font-semibold mt-2 hover:text-primary/70 transition-all duration-300"
          >
            <span>{question}</span>

            {showAnswer ? (
              <FaMinus className="inline-block ml-2 mr-10 group-hover:mr-[10%]" />
            ) : (
              <FaPlus className="inline-block ml-2 group-hover:mr-[10%]" />
            )}
          </button>
          <div className="flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 absolute top-2.5 right-1">
            <button
              onClick={() => {
                navigate(id);
              }}
              title="Edit"
              className="bg-primary hover:bg-primary/70 text-white font-semibold text-sm p-2 rounded-full"
            >
              <AiOutlineEdit />
            </button>

            <button
              onClick={() => {
                setOnDelete(true);
                const queryParams = new URLSearchParams();

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                queryParams.set("faqId", id as string);

                navigate({
                  search: `?${queryParams.toString()}`,
                });
              }}
              title="Delete"
              className="bg-primary hover:bg-primary/70 text-white font-semibold text-sm p-2 rounded-full"
            >
              <BiTrash />
            </button>
          </div>
        </div>

        {showAnswer && <p className="px-4 py-2">{answer}</p>}
      </div>
    </>
  );
};

export default FaqCard;
