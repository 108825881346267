import { GLAM_N_GO_API } from "../../apiSlice";

export const editOrderStageApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editOrderStage: builder.mutation({
      query: (order) => {
        return {
          url: `/orders/updateOrderStatus/${order.id}`,
          method: "PATCH",
          body: order,
        };
      },
      invalidatesTags: ["ORDER"],
    }),
  }),
});

export const { useEditOrderStageMutation } = editOrderStageApiSlice;
