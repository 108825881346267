import { GLAM_N_GO_API } from "../../apiSlice";

export const getShareMessageApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getShareMessage: builder.query({
      query: () => ({
        url: "/FAQs/getShareMessage",
        providesTags: ["SHARE_MESSAGE"],
      }),
    }),
  }),
});

export const { useGetShareMessageQuery } = getShareMessageApiSlice;
