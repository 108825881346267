import { GLAM_N_GO_API } from "../../apiSlice";

export const editShareMessageApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editShareMessage: builder.mutation({
      query: (message) => {
        return {
          url: "/FAQs/editShareMessage",
          method: "PATCH",
          body: message,
        };
      },
      invalidatesTags: ["SHARE_MESSAGE"],
    }),
  }),
});

export const { useEditShareMessageMutation } = editShareMessageApiSlice;
