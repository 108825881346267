// eslint-disable @typescript-eslint/no-unused-vars

import React, { useEffect, useState } from "react";
import {
  SectionTitle,
  GGButton,
  Spinner,
  BreadCrumb,
  Loader,
} from "components";
import { Formik } from "formik";
import { type ToastContent, toast } from "react-toastify";
import {
  useNewCategoryMutation,
  useUploadImageMutation,
  useLazyGetCategoryQuery,
  useEditCategoryMutation,
  useGetSubCategoriesByCategoryIdQuery,
} from "store/api";
import { useNavigate, useParams } from "react-router-dom";
import CategoryForm from "./CategoryForm";
import { ProductCategorySchema } from "../schemas";

import { getCloudFlareImageUrl } from "utils";

const AddCategory = ({ isEdit }: { isEdit?: boolean }) => {
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<any>({});
  const [newCategory, { isLoading }] = useNewCategoryMutation();
  const [getCategory, { isFetching, isLoading: isLoadingCategory }] =
    useLazyGetCategoryQuery();

  const [editCategory, { isLoading: isEditingCategory }] =
    useEditCategoryMutation();
  const [uploadImage, { isLoading: uploadImageIsLoading }] =
    useUploadImageMutation();
  const navigate = useNavigate();

  const { data } = useGetSubCategoriesByCategoryIdQuery({ id: categoryId });

  const subCategories = data?.subCategories || [];

  const fetchCategoryData = async () => {
    const fetchedCategory = await getCategory({ id: categoryId }).unwrap();
    setCategory(fetchedCategory.category);
  };

  useEffect(() => {
    if (isEdit && categoryId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchCategoryData();
    }
  }, [isEdit, categoryId]);

  const newCategorySubmitHandler = async (values: any) => {
    try {
      setLoading(true);
      let imageUrl = "";
      if (values.imageLink) {
        imageUrl = values.imageLink;
      } else {
        const imageUploadRes = await uploadImage(values.image).unwrap();
        imageUrl = getCloudFlareImageUrl(imageUploadRes?.result);
      }
      await newCategory({
        name: values.name,
        description: values.description,
        image: imageUrl,
        isHidden: true,
      }).unwrap();

      navigate("../product-categories");
      toast.success(`${values.name} added successfully`);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message as ToastContent<unknown>);
    }
  };
  const editCategorySubmitHandler = async (values: any) => {
    try {
      setLoading(true);
      if (values.image instanceof Blob && !values.imageLink) {
        const imageUploadRes = await uploadImage(values.image).unwrap();
        values.image = getCloudFlareImageUrl(imageUploadRes?.result);
      }

      await editCategory({
        id: categoryId,
        name: values.name,
        description: values.description,
        image: values.imageLink || values.image,
        isHidden: values.isHidden,
      }).unwrap();
      navigate("../product-categories");
      toast.success(`${values.name} added successfully`);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message as ToastContent<unknown>);
    }
  };

  return (
    <div>
      <BreadCrumb lastName={isEdit ? category.name : null} />

      <div className="card">
        {isFetching || isLoadingCategory ? (
          <Loader />
        ) : (
          <>
            <SectionTitle
              title={isEdit ? "Edit Category Details" : "Category Details"}
            />
            <Formik
              enableReinitialize
              initialValues={{
                name: category?.name || "",
                description: category?.description || "",
                image: category?.image || null,
                subCategories: subCategories || [],
                isHidden: category?.isHidden || false,
                imageLink: null,
              }}
              validationSchema={ProductCategorySchema}
              onSubmit={async (values, { resetForm }) => {
                if (isEdit) {
                  await editCategorySubmitHandler(values);
                } else {
                  await newCategorySubmitHandler(values);
                }

                resetForm();
              }}
            >
              {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
                <form data-gtm-form-interact-id="0" onSubmit={handleSubmit}>
                  <CategoryForm
                    values={values}
                    setFieldValue={setFieldValue}
                    isEdit={isEdit}
                  />
                  <div className="flex justify-end gap-2 my-3 pt-3">
                    <GGButton
                      type="submit"
                      disable={
                        isLoading ||
                        uploadImageIsLoading ||
                        !dirty ||
                        !isValid ||
                        loading ||
                        isEditingCategory
                      }
                    >
                      {isLoading ||
                      uploadImageIsLoading ||
                      loading ||
                      isEditingCategory ? (
                        <Spinner />
                      ) : isEdit ? (
                        "Save Changes"
                      ) : (
                        "Add Category"
                      )}
                    </GGButton>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default AddCategory;
