import { GLAM_N_GO_API } from "../../apiSlice";

export const updateUserAPiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    updateUser: builder.mutation({
      query: (data) => {
        return {
          url: "auth/updatedMe",
          method: "PATCH",
          body: data,
        };
      },
    }),
  }),
});

export const { useUpdateUserMutation } = updateUserAPiSlice;
