import React, { type FC } from "react";
import { FormControl } from "components/UI";
import { type FormComponentProps } from "interfaces";
import { ImageUpload, Input } from "components/Common";
import { useGetAllCategoriesQuery } from "store/api";

const BannerForm: FC<FormComponentProps> = ({ setFieldValue, values }) => {
  const { data } = useGetAllCategoriesQuery("categories");

  const categories = data?.categories ?? [];
  return (
    <div>
      <FormControl>
        <ImageUpload
          fieldName="image"
          setFieldValue={setFieldValue}
          imageUrl={values.image}
          linkName="imageLink"
        />
      </FormControl>
      <FormControl>
        <Input
          name="title"
          type="text"
          label="Title"
          note="Required"
          value={values.title}
        />
      </FormControl>
      <FormControl>
        <Input
          name="type"
          type="select"
          label="Type"
          note="Required"
          value={values.type}
          options={[
            {
              label: "Slider",
              value: "slider",
            },
            {
              label: "Category",
              value: "category",
            },
            {
              label: "Women",
              value: "women",
            },
            {
              label: "Kids",
              value: "kids",
            },
            {
              label: "Men",
              value: "men",
            },
          ]}
        />
      </FormControl>
      <FormControl>
        <Input
          name="description"
          type="textarea"
          label="Description"
          note="Required"
          value={values.description}
        />
      </FormControl>
      {values.type === "category" && (
        <FormControl>
          <Input
            type="select"
            name="categoryId"
            label="Category"
            value={values.categoryId}
            options={categories.map((category: any) => ({
              label: category.name,
              value: category.docId,
            }))}
          />
        </FormControl>
      )}
    </div>
  );
};

export default BannerForm;
