import { GLAM_N_GO_API } from "../../apiSlice";

export const getOrderStatisticsApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getOrderStatistics: builder.query({
      query: () => ({
        url: `/orders/getOrderStats`,
        providesTags: ["ORDER"],
      }),
    }),
  }),
});

export const { useGetOrderStatisticsQuery } = getOrderStatisticsApiSlice;
