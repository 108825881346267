/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import { BreadCrumb, GGButton, Loader } from "components";
import { BiSolidBadgeCheck } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import {
  useApproveUserMutation,
  useGetUserQuery,
  useRejectUserMutation,
} from "store/api";
import { toast, type ToastContent } from "react-toastify";

const SingleUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [approveUser, { isLoading: approveIsLoading }] =
    useApproveUserMutation();
  const [rejectUser, { isLoading: isDeactivating }] = useRejectUserMutation();
  const { data, isFetching } = useGetUserQuery({
    id: userId,
  });

  const user = data?.user;
  const isVerified = user?.isVerified;

  const approveVendorHandler = async () => {
    try {
      await approveUser({
        email: user?.email,
      }).unwrap();
      toast.success("Vendor Approved");
      navigate("../users");
    } catch (error: any) {
      toast.error(error?.data?.message as ToastContent<unknown>);
    }
  };

  const rejectVendorHandler = async () => {
    try {
      await rejectUser({
        email: user?.email,
      }).unwrap();
      toast.success("Vendor Rejected");
    } catch (error: any) {
      toast.error(error?.data?.message as ToastContent<unknown>);
    }
  };

  const {
    firstName,
    lastName,
    email,
    userName,
    phone,
    image,
    role,
    address,
    district,
    city,
  } = user || {};

  const location =
    `${address ?? "N/A"} ${city ?? ""} ${district ?? ""}` ?? "N/A";

  return (
    <div>
      <BreadCrumb lastName={user?.userName ?? "UserName"} />

      <div className="card">
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <div className="flex gap-4">
              <div className="flex gap-4">
                <div>
                  <img
                    src={image ?? "https://via.placeholder.com/220"}
                    alt={userName ?? "User Name"}
                    className="rounded-md w-40"
                  />
                </div>
                <div>
                  <div className="flex items-center gap-3">
                    <h4 className="font-poppins capitalize font-bold text-md truncate max-w-[220px] xs:max-w-[260px] md:max-w-full xl:max-w-[218px]">
                      {firstName ?? userName} {lastName ?? ""}
                    </h4>
                    <BiSolidBadgeCheck
                      title={isVerified ? "Verified" : "Not Verified"}
                      className={` ${isVerified ? "text-primary" : "text-slate-300"} h-5 w-5 cursor-pointer`}
                    />
                  </div>
                  <p className="text-muted text-xs">@{user?.userId}</p>
                  <span className="inline-flex  mt-2 capitalize items-center justify-center  h-[22px] rounded bg-linear text-white font-poppins font-medium text-sm px-[12px]">
                    {role}
                  </span>

                  <p className="font-poppins font-normal text-sm leading-6 my-3">
                    {location}
                  </p>
                  <div className="flex flex-col gap-1 text-sm font-poppins font-normal text-textColor">
                    <a href={`mailto:${email}`} className="hover:text-primary">
                      {email}
                    </a>
                    <a
                      href={`tel:+256${phone}`}
                      className="hover:text-primary  transition-all duration-300"
                    >
                      +256{phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center max-sm:flex-wrap mt-4">
              {!isVerified ? (
                <GGButton
                  width="100%"
                  type="button"
                  onClick={approveVendorHandler}
                  disable={approveIsLoading || isDeactivating}
                >
                  <span className="font-semibold">Activate</span>
                </GGButton>
              ) : (
                <button
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={rejectVendorHandler}
                  disabled={approveIsLoading || isDeactivating}
                  type="button"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-semibold rounded-full shadow-sm bg-primaryLight text-primary hover:bg-primary/20 focus:outline-none"
                >
                  Deactivate
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SingleUser;
