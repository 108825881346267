import { GLAM_N_GO_API } from "../../apiSlice";

export const editCategoryApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editCategory: builder.mutation({
      query: ({ id, ...rest }) => {
        return {
          url: `/categories/update/${id}`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: ["CATEGORIES"],
    }),
  }),
});

export const { useEditCategoryMutation } = editCategoryApiSlice;
