import { GLAM_N_GO_API } from "../../apiSlice";

export const updatePasswordApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    updatePassword: builder.mutation({
      query: (data) => {
        return {
          url: "/auth/changePassword",
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["USERS"],
    }),
  }),
});

export const { useUpdatePasswordMutation } = updatePasswordApiSlice;
