/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { type FC, useEffect } from "react";
import { FormControl } from "components/UI";
import { socialMedia } from "data/data";
import { Input } from "components/Common";
import { type FormComponentProps } from "interfaces";
import { useGetSocialsQuery } from "store/api";

const SocialForm: FC<FormComponentProps> = ({
  values,
  setFieldValue,
  isEdit,
}) => {
  const { data } = useGetSocialsQuery("socials");
  const socials = data?.socials;
  const [prepend, setPrepend] = React.useState("");

  const filterSocialMedia = () => {
    if (isEdit) {
      return socialMedia;
    }
    return socialMedia.filter(
      (localItem: any) =>
        !socials?.some(
          (serverItem: any) => localItem.name === serverItem.social,
        ),
    );
  };

  useEffect(() => {
    const selectedSocial = filterSocialMedia()?.find(
      (social) => social.name === values.social,
    );

    if (selectedSocial) {
      setPrepend(selectedSocial.link);
    }

    return () => {
      setPrepend("");
    };
  }, [values.social]);

  useEffect(() => {
    setFieldValue("link", prepend.replace(/username/g, values.userName));
  }, [prepend, values.userName]);

  return (
    <div>
      <FormControl>
        <Input
          type="select"
          name="social"
          label="Social Media Platform"
          note="required"
          value={values.social}
          options={filterSocialMedia().map((social) => ({
            label: social.name,
            value: social.name,
          }))}
        />
      </FormControl>
      <FormControl>
        <Input
          prepend={prepend?.split("username")[0]}
          type="text"
          name="userName"
          label="Handle"
          placeholder="social handle"
          note="Just type the user handle without the @ symbol"
          value={values.userName}
        />
        {values.userName && (
          <Input
            type="text"
            name="link"
            placeholder="Link Preview"
            label="Link Preview"
            note="Here is the preview of the link."
            disabled
            value={prepend.replace(/username/g, values.userName)}
          />
        )}
      </FormControl>
    </div>
  );
};

export default SocialForm;
