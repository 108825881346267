import { GLAM_N_GO_API } from "../../apiSlice";

export const rejectShopApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    rejectShop: builder.mutation({
      query: (Shop) => {
        const data = new FormData();
        data.append("id", String(Shop.id));

        return {
          url: "/stores/rejectStore",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["SHOPS", "SHOP"],
    }),
  }),
});

export const { useRejectShopMutation } = rejectShopApiSlice;
