import React, { useState } from "react";
import { RichTextEditor, Spinner } from "components";
import { useFormik } from "formik";
import { useEditStaticPageMutation } from "store/api";
import { type ToastContent, toast } from "react-toastify";

const PageTemplate = ({
  page,
  placeholder,
  value,
  id,
}: {
  page: string;
  placeholder: string;
  value: string;
  id: string;
}) => {
  const [editStaticPage, { isLoading }] = useEditStaticPageMutation();
  const [htmlValues, setHtmlValues] = useState(value);

  const { handleSubmit } = useFormik({
    initialValues: {
      content: value,
    },
    onSubmit: async () => {
      try {
        const data = {
          content: htmlValues,
          id,
        };
        await editStaticPage(data).unwrap();
        toast.success("Page updated successfully");
      } catch (error: any) {
        toast.error(error.message as ToastContent<unknown>);
      }
    },
  });
  return (
    <div className="bg-white p-3 rounded-md my-3">
      <div className="flex items-center justify-between mb-3 ">
        <h4 className=" font-semibold text-md capitalize ">{page}</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <RichTextEditor
          value={htmlValues}
          placeholder={placeholder}
          onChange={(e: string) => {
            setHtmlValues(e);
          }}
        />
        <div className="flex items-center justify-end mt-3">
          <button
            type="submit"
            className=" bg-primary text-white  rounded-full px-4 py-2 text-sm font-semibold mt-2 hover:bg-primary-shade transition-all duration-300"
          >
            {isLoading ? <Spinner /> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PageTemplate;
