import { Loader, StaticPageTemplate } from "components";
import React from "react";
import { useGetStaticPagesQuery } from "store/api";

const Delivery = () => {
  const { data, isFetching } = useGetStaticPagesQuery("staticPages");
  const page = data?.pages.find((item: any) => item.pageId === "DELVERY_PAGE");

  return (
    <div>
      {isFetching ? <Loader showText /> : <StaticPageTemplate page={page} />}
    </div>
  );
};

export default Delivery;
