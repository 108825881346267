import React, { useState } from "react";
import { BreadCrumb, DeleteDialog, Loader } from "components";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDeleteSocialMutation, useGetSocialsQuery } from "store/api";
import { AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";

import ShareMessage from "./ShareMessage";

const CompanySocials = () => {
  const navigate = useNavigate();
  const { data, isFetching, refetch } = useGetSocialsQuery("socials");
  const socials = data?.socials;
  const [deleteSocial, { isLoading }] = useDeleteSocialMutation();
  const [onDelete, setOnDelete] = useState(false);

  const onDeleteHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    const selectedId = searchParams.get("socialId");
    try {
      await deleteSocial({ id: selectedId as string })
        .unwrap()
        .then(async () => {
          await refetch();
        });
      navigate({
        search: "",
      });
      setOnDelete(false);
      toast.success("Social deleted successfully");
    } catch (e) {
      toast.error("Failed to delete social");
    }
  };

  const cancelHandler = () => {
    setOnDelete(false);
    navigate({
      search: "",
    });
  };

  return (
    <div>
      <DeleteDialog
        show={onDelete}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onConfirm={onDeleteHandler}
        onCancel={cancelHandler}
        isLoading={isLoading}
        itemName="social"
        actionName="Delete"
      />
      <div>
        <BreadCrumb />
      </div>
      <div className="p-3">
        {isFetching ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-wrap gap-3 items-center">
            {socials?.map((social: any) => (
              <div
                key={social.link}
                className=" group flex justify-between items-center gap-2 rounded-md cursor-pointer hover:shadow-lg p-3 shadow-md bg-white"
              >
                <div className="flex items-center gap-2">
                  <img
                    src={social.socialIcon}
                    alt={social.social}
                    className="w-6 h-6"
                  />
                  <p className="text-sm font-semibold">{social.social}</p>
                </div>
                <div className="items-center justify-center gap-4 hidden group-hover:flex transition-all duration-300 ease-in-out">
                  <button
                    onClick={() => {
                      navigate(`${social.docId}`);
                    }}
                    title="Edit"
                    className="bg-white text-primary font-semibold text-sm  rounded-full "
                  >
                    <AiOutlineEdit />
                  </button>

                  <button
                    onClick={() => {
                      setOnDelete(true);
                      const queryParams = new URLSearchParams();
                      queryParams.set("socialId", social?.docId as string);

                      navigate({
                        search: `?${queryParams.toString()}`,
                      });
                    }}
                    title="Delete"
                    className="bg-white text-primary font-semibold text-sm  rounded-full"
                  >
                    <BiTrash />
                  </button>
                </div>
              </div>
            ))}
            <div
              className=" group flex items-center gap-2 rounded-md cursor-pointer hover:shadow-lg hover:bg-primaryLight p-3 shadow-md bg-transparent border-dashed border border-primary hover:border-primaryLight"
              onClick={() => {
                navigate("add-social");
              }}
            >
              <FaPlus />
              <p className="text-sm font-semibold">Add New</p>
            </div>
          </div>
        )}
      </div>

      <div className="card my-4">
        <ShareMessage />
      </div>
    </div>
  );
};

export default CompanySocials;
