import React, { useState } from "react";
import { BreadCrumb, Button, Dropzone, GGButton } from "components";
import { Formik } from "formik";
import { useUploadImageMutation } from "store/api";
import { formatBytes, getImageUrl } from "utils";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import { MdCheckCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const NewUpload = () => {
  const [uploadImage, { isLoading: uploadImageIsLoading }] =
    useUploadImageMutation();
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const navigate = useNavigate();

  const uploadNewImageSubmitHandler = async (values: any) => {
    if (values.gallery.length > 0) {
      const id = toast.loading("Uploading images, Please wait...", {
        position: "bottom-right",
      });
      await Promise.all(
        values.gallery.map(async (g: File) => {
          const imageUploadRes1 = await uploadImage(g).unwrap();
          setUploadedImages((prev) => [...prev, g]);
          return imageUploadRes1;
        }),
      );
      toast.update(id, {
        render: "Images uploaded successfully",
        type: "success",
        isLoading: false,
      });
      setTimeout(() => {
        toast.dismiss(id);
      }, 3000);
      navigate("/dashboard/media");
    }
  };
  return (
    <div>
      <BreadCrumb />
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={{
            gallery: [],
          }}
          onSubmit={async (values) => {
            await uploadNewImageSubmitHandler(values);
          }}
        >
          {({ handleSubmit, values, setFieldValue, dirty, isValid }) => (
            <form data-gtm-form-interact-id="043" onSubmit={handleSubmit}>
              <Dropzone
                multiple
                onDrop={(f) => {
                  setFieldValue("gallery", [...values.gallery, ...f]);
                }}
                accept={{
                  "image/*": [".png", ".jpg", ".jpeg"],
                }}
                className="py-20"
                hideFiles
              />
              {values.gallery.length > 0 && (
                <div className="mt-2">
                  <h3 className="font-semibold mb-2">Selected Images</h3>
                  {values.gallery.map((g: File, i) => {
                    const isUploaded = !!uploadedImages.find(
                      (img) => img.name === g.name,
                    );
                    return (
                      <div
                        className="flex items-center justify-between gap-2 w-full card rounded-md p-1 cursor-pointer my-1"
                        key={i}
                      >
                        <div className="flex gap-3">
                          <div className="w-12 h-12 bg-primary rounded-full">
                            <img
                              src={getImageUrl(g)}
                              alt={g.name}
                              className="w-full h-full object-cover rounded-md"
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <div className="font-semibold flex items-center gap-3">
                              {g.name}
                            </div>
                            <div className="text-sm text-gray-500 line-clamp-2">
                              {formatBytes(g.size)}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-2 items-center">
                          {isUploaded ? (
                            <MdCheckCircle
                              className="text-green-600"
                              size={20}
                            />
                          ) : (
                            ""
                          )}
                          <Button
                            type="button"
                            variant="secondary"
                            className="text-red-500"
                            disabled={uploadImageIsLoading || isUploaded}
                            onClick={() => {
                              setFieldValue(
                                "gallery",
                                values.gallery.filter(
                                  (_, index) => index !== i,
                                ),
                              );
                            }}
                          >
                            <RiDeleteBin5Fill />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="flex justify-center gap-2 my-3 pt-3">
                <GGButton
                  type="submit"
                  disable={uploadImageIsLoading || !dirty || !isValid}
                  loading={uploadImageIsLoading}
                >
                  {uploadImageIsLoading
                    ? "Uploading..."
                    : `Upload ${values.gallery.length > 0 ? `(${values.gallery.length})` : ""}`}
                </GGButton>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewUpload;
