import { GLAM_N_GO_API } from "../../apiSlice";

export const getShopOrdersApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getShopOrders: builder.query({
      query: (shop) => ({
        url: `/stores/getStoreOrders/${shop.id}`,
        providesTags: ["ORDERS", "SHOP"],
      }),
    }),
  }),
});

export const { useGetShopOrdersQuery } = getShopOrdersApi;
