import { GLAM_N_GO_API } from "../../apiSlice";

export const getStaticPagesApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getStaticPages: builder.query({
      query: () => ({
        url: "/pages/getAll",
        providesTags: ["PAGES"],
      }),
    }),
  }),
});

export const { useGetStaticPagesQuery } = getStaticPagesApi;
