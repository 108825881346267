import { GLAM_N_GO_API } from "store/api/apiSlice";

export const deleteImageAPiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteImage: builder.mutation({
      query: (image) => {
        const parts = image.split("/");
        const imageId = parts[parts.length - 2];
        return {
          url: `/files/deleteFile/${imageId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const { useDeleteImageMutation } = deleteImageAPiSlice;
