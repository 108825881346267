import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineBars3BottomLeft } from "react-icons/hi2";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import USER from "../../assets/images/profile-circle.svg";
import { NavLink, useNavigate } from "react-router-dom";
import GGButton from "../UI/GGButton";
import { logOut, selectUser } from "store/slices";

const Header = ({
  setShowMenu,
}: {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropDownRef.current === null) return;

      if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setOpen(!open);
  };
  const logOutHandler = () => {
    dispatch(logOut());
    navigate("/login");
  };

  const user = useSelector(selectUser);

  const dropDownLinks = [{ name: "Orders" }, { name: "Profile" }];
  return (
    <div className="flex justify-between z-30 w-full fixed left-0 top-0 md:px-8  items-center  border-[#dbe0eb] border-b py-3 bg-white">
      <div className=" flex items-center ml-1">
        <button
          type="button"
          className="md:hidden text ml-2"
          onClick={() => {
            setShowMenu(true);
          }}
        >
          <HiOutlineBars3BottomLeft className="h-[32px] w-[32px]" />
        </button>
        <h2 className="text-textColor font-semibold font-poppins text-md lg:text-xl whitespace-nowrap capitalize max-md:hidden md:pl-[250px]">
          Welcome back {user?.lastName ?? ""}
        </h2>
      </div>
      <div className="flex justify-end items-center ">
        <div className="flex items-center gap-4 mx-4">
          <nav ref={dropDownRef} className="relative">
            <button
              type="button"
              onClick={toggleDropdown}
              className="flex items-center text-[#273144] rounded-md cursor-pointer bg-transparent hover:bg-[#f4f6fa] focus:bg-[#f4f6fa] focus:shadow-[#9db8f8] px-3 py-1"
            >
              <img
                src={user?.image ?? USER}
                alt={user?.userName ?? "User"}
                className="!w-[40px] !h-[40px] rounded-full mr-1"
              />
              <div className="mr-1 w-full">
                <h3 className="font-poppins font-bold text-lg max-md:text-sm capitalize whitespace-nowrap overflow-hidden text-ellipsis">
                  {user?.firstName + " " + user?.lastName ?? "User"}
                </h3>
              </div>
              <div>
                {!open ? (
                  <IoIosArrowDown className="h-[16px] w-[16px]" />
                ) : (
                  <IoIosArrowUp className="h-[16px] w-[16px]" />
                )}
              </div>
            </button>
            {open && (
              <div className="absolute bg-white shadow-2xl rounded top-[3.8rem] px-4 py-2 max-sm:right-2 md:right-2 w-full">
                <ul>
                  {dropDownLinks.map((link) => (
                    <NavLink to={link.name} key={link.name}>
                      <li className="text-md text-poppins font-medium capitalize py-1 px-2 rounded whitespace-nowrap hover:bg-primary hover:text-white cursor-pointer">
                        {link.name}
                      </li>
                    </NavLink>
                  ))}
                </ul>
                <div className="mt-2 border-t border-t-borderColor">
                  <GGButton type="button" onClick={logOutHandler}>
                    Log Out
                  </GGButton>
                </div>
              </div>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
