import { GLAM_N_GO_API } from "../../apiSlice";

export const deliveryRegionsSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveryRegions: builder.query({
      query: () => ({
        url: `/FAQs/getTransportCostsRegions`,
        providesTags: ["D_LOCATIONS"],
      }),
    }),
    getDeliveryLocationsByRegion: builder.query({
      query: (region) => ({
        url: `/FAQs/getTransportCostsForRegion/${region}`,
        providesTags: ["D_LOCATION"],
      }),
    }),
    addNewDeliveryLocation: builder.mutation({
      query: (data) => ({
        url: `/FAQs/addNewTransportCost`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["D_LOCATIONS"],
    }),
    updateDeliveryLocation: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/FAQs/editTransportCost/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["D_LOCATION"],
    }),

    deleteDeliveryLocation: builder.mutation({
      query: ({ id, region }) => ({
        url: `/FAQs/deleteTransportCost/${region}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["D_LOCATIONS"],
    }),
  }),
});

export const {
  useGetDeliveryRegionsQuery,
  useGetDeliveryLocationsByRegionQuery,
  useAddNewDeliveryLocationMutation,
  useUpdateDeliveryLocationMutation,
  useDeleteDeliveryLocationMutation,
  useLazyGetDeliveryLocationsByRegionQuery,
} = deliveryRegionsSlice;
