import { GLAM_N_GO_API } from "../../apiSlice";

export const editOnBoardingApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editOnBoarding: builder.mutation({
      query: (data) => {
        return {
          url: "/FAQs/addScreen",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["SCREENS"],
    }),
  }),
});

export const { useEditOnBoardingMutation } = editOnBoardingApiSlice;
