import { GLAM_N_GO_API } from "../../apiSlice";

export const deleteOrderApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    deleteOrder: builder.mutation({
      query: (product) => ({
        url: `/orders/delete/${product.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ORDERS"],
    }),
  }),
});

export const { useDeleteOrderMutation } = deleteOrderApiSlice;
