import { createSlice } from "@reduxjs/toolkit";

interface UserProps {
  id?: string;
  name?: string;
  email?: string;
  role?: string;
  isVerified?: boolean;
  createdAt?: {
    _seconds: number;
    _nanoseconds: number;
  };
}

// interface StoreProps{
//   image?: string;
//   name?: string;
//   description?: string;
// }

interface AuthState {
  accessToken: string;
  isAuthenticated: boolean;
  storeId: string;
  user: UserProps;
}

const defaultUser: UserProps = {
  id: undefined,
  name: undefined,
  email: undefined,
  role: undefined,
  isVerified: undefined,
  createdAt: undefined,
};

const initialState: AuthState = {
  accessToken: "",
  isAuthenticated: false,
  user: defaultUser,
  storeId: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    autoAuthenticate: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.user = payload.user;
      state.isAuthenticated = true;
    },
    login: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.user = payload.user;
      state.storeId = payload.storeId;
      state.isAuthenticated = true;
    },
    updateUserState: (state, { payload }) => {
      state.user = payload.user;
    },
    logOut: (state) => {
      state.accessToken = "";
      state.isAuthenticated = false;
      state.storeId = "";
      state.user = {};
    },
  },
});

export const { login, logOut, updateUserState } = authSlice.actions;

export const selectIsAuthenticated = (state: { auth: any }) =>
  state.auth.isAuthenticated;

export const selectAccessToken = (state: { auth: any }) =>
  state.auth.accessToken;
export const selectUser = (state: { auth: any }) => state.auth.user;
export const selectStoreId = (state: { auth: any }) => state.auth.storeId;

export default authSlice.reducer;
