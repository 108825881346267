import { GLAM_N_GO_API } from "../../apiSlice";

export const getProductByIdApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getProductById: builder.query({
      query: (data) => ({
        url: `/products/${data.id}`,
        providesTags: ["PRODUCTS"],
      }),
    }),
  }),
});

export const { useGetProductByIdQuery, useLazyGetProductByIdQuery } =
  getProductByIdApiSlice;
