import React, { useEffect, useState } from "react";
import {
  BreadCrumb,
  GGButton,
  GGTable,
  Loader,
  Modal,
  SINGLE_ORDER_COLUMNS,
  SectionTitle,
} from "components";
import { useParams } from "react-router-dom";
import {
  useGetAllShopsQuery,
  useGetOrderProductsQuery,
  useGetOrderQuery,
  useRemoveProductFromOrderMutation,
} from "store/api";
import EditOrder from "components/Forms/EditOrder";
import { formattedDate, formattedPrice } from "utils";
import { type FirebaseDate } from "interfaces";
import { toast } from "react-toastify";
import OrderProduct from "components/Forms/OrderProduct";

const SingleOrder = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [productToDelete, setProductToDelete] = useState({} as any);
  const [productToEdit, setProductToEdit] = useState({} as any);
  const [isProductEdit, setIsProductEdit] = useState(false);
  const [removeProductFromOrder, { isLoading: isDeleting }] =
    useRemoveProductFromOrderMutation();

  const { orderId } = useParams();
  const { data, isFetching, refetch } = useGetOrderQuery({ id: orderId });
  const fetchedOrder = data?.Order;

  const { data: orderProducts, isFetching: isFetchingOrderProducts } =
    useGetOrderProductsQuery({ id: orderId });

  const { data: stores } = useGetAllShopsQuery("shops");
  const storesData = stores?.stores;

  const ORDER_ITEMS =
    orderProducts?.products || fetchedOrder?.orderDetails || [];

  const mappedOrderItems = ORDER_ITEMS.map((item: { storeId: any }) => {
    const product = storesData?.find(
      (store: { docId: any }) => store.docId === item.storeId,
    );
    return {
      ...item,
      storeName: product?.name,
    };
  });

  const onDeleteHandler = async () => {
    try {
      setModalOpen(false);
      await removeProductFromOrder({
        productId: productToDelete.productId,
        quantity: productToDelete.quantity,
      }).unwrap();
      toast.success("Product Deleted Successfully");
      // navigate("../products");
    } catch (error) {
      toast.error("Failed to delete product, Please try again later.");
    }
  };

  const searchParams = new URLSearchParams(location.search);
  const editSelected = searchParams.get("edit");

  useEffect(() => {
    if (editSelected) {
      setIsEdit(true);
    }
  }, [editSelected]);

  return (
    <div>
      {isFetching ? (
        <Loader showText />
      ) : (
        <div>
          <BreadCrumb lastName={fetchedOrder?.orderNumber} />
          <div className="bg-white rounded-md p-5 mb-6">
            <div className="flex items-center flex-wrap gap-4 justify-between border-b border-[#eee]">
              <SectionTitle title={`Order #${fetchedOrder?.orderNumber}`} />
              <div className="flex items-center flex-wrap flex-column-reverse gap-4 mb-6">
                <button
                  type="button"
                  onClick={() => {
                    setIsEdit((prev) => !prev);
                  }}
                  className="text-sm font-medium bg-primaryLight text-primary hover:bg-primary/20 px-4 py-1 rounded-full disabled:bg-[#fceff3] disabled:cursor-not-allowed"
                >
                  {isEdit ? "View" : "Edit"}
                </button>
                <button
                  type="button"
                  className="flex items-center gap-1 text-sm font-medium bg-primary hover:bg-primary/80 text-white px-4 py-1 rounded-full disabled:bg-[#fceff3] disabled:cursor-not-allowed"
                >
                  Mark As Completed
                </button>
              </div>
            </div>
            {isEdit ? (
              <EditOrder order={fetchedOrder} refetch={refetch} />
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2  my-6">
                <div className="flex flex-col gap-4">
                  <div>
                    <p className="text-sm font-medium text-slate-800/50">
                      Customer Details
                    </p>
                    <p className="text-xs font-medium">{fetchedOrder?.owner}</p>
                    {/* <p className="text-xs font-medium">{fetchedOrder?.owner}</p> */}
                    <p className="text-xs font-medium">
                      {fetchedOrder?.shippingAddress?.shoppingPhoneNumber}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-slate-800/50">
                      Order Date
                    </p>
                    <p className="text-xs font-medium">
                      {formattedDate(fetchedOrder?.createdAt as FirebaseDate)}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-slate-800/50">
                      Order Status
                    </p>
                    <p className="text-xs font-medium">
                      {fetchedOrder?.orderStatus}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-slate-800/50">
                      Payment Method
                    </p>
                    <p className="text-xs font-medium capitalize">
                      {fetchedOrder?.paymentMethod}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  <div>
                    <p className="text-sm font-medium text-slate-800/50">
                      Shipping Details
                    </p>
                    <div className="flex flex-col gap-2">
                      <div>
                        <p className="text-xs font-medium text-slate-800/50">
                          Division
                        </p>
                        <p className="text-xs font-medium">
                          {fetchedOrder?.shippingAddress?.division}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs font-medium text-slate-800/50">
                          Region
                        </p>
                        <p className="text-xs font-medium">
                          {fetchedOrder?.shippingAddress?.region}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs font-medium text-slate-800/50">
                          Shipping Email
                        </p>
                        <p className="text-xs font-medium">
                          {fetchedOrder?.shippingAddress?.shippingEmail}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs font-medium text-slate-800/50">
                          Shipping Name
                        </p>
                        <p className="text-xs font-medium">
                          {fetchedOrder?.shippingAddress?.shippingName}
                        </p>
                      </div>
                      <div>
                        {" "}
                        <p className="text-xs font-medium text-slate-800/50">
                          Phone Number
                        </p>
                        <p className="text-xs font-medium">
                          {fetchedOrder?.shippingAddress?.shoppingPhoneNumber}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs font-medium text-slate-800/50">
                          Shipping Price
                        </p>
                        <p className="text-xs font-medium">
                          {formattedPrice(
                            fetchedOrder?.shippingAddress
                              ?.shippingPrice as string,
                            "UGX",
                          )}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs font-medium text-slate-800/50">
                          Full Address
                        </p>
                        <p className="text-xs font-medium">
                          {fetchedOrder?.shippingAddress?.fullAddress}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs font-medium text-slate-800/50">
                          Delivery Notes
                        </p>
                        <p className="text-xs font-medium">
                          {fetchedOrder?.shippingAddress?.deliveryNotes}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-slate-800/50">
                      Shipping Method
                    </p>
                    <p className="text-xs font-medium">Glam Express</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className=" bg-white p-5">
            <Modal
              open={modalOpen}
              setOpen={setModalOpen}
              center
              body={
                <div className="flex flex-col gap-2">
                  <h3 className="text-lg font-semibold">
                    Delete Order Product
                  </h3>
                  <p className="text-sm">
                    Are you sure you want to delete this order product?, This
                    action is permanent and cannot be undone.
                  </p>
                  <div className="flex justify-end gap-2">
                    <GGButton
                      type="button"
                      variant="outlined"
                      onClick={() => setModalOpen(false)}
                    >
                      Cancel
                    </GGButton>
                    <GGButton
                      type="button"
                      onClick={onDeleteHandler}
                      loading={isDeleting}
                    >
                      Yes, Delete
                    </GGButton>
                  </div>
                </div>
              }
            />
            <div>
              <SectionTitle title="Order Items" />
            </div>
            <div>
              {addNew ? (
                <OrderProduct
                  order={fetchedOrder}
                  refetch={refetch}
                  close={() => setAddNew(false)}
                  productDetails={productToEdit}
                  isEdit={isProductEdit}
                />
              ) : (
                <GGTable
                  onDeleteHandler={(selectedProduct) => {
                    setProductToDelete(selectedProduct);
                    setModalOpen(true);
                  }}
                  onEditHandler={(selectedProduct) => {
                    setAddNew(true);
                    setIsProductEdit(true);
                    setProductToEdit(selectedProduct);
                  }}
                  addButtonText="Add Product"
                  onAddNewHandler={() => setAddNew(true)}
                  data={mappedOrderItems || []}
                  columns={SINGLE_ORDER_COLUMNS}
                  isLoading={isFetching || isFetchingOrderProducts}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleOrder;
