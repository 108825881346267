import { GLAM_N_GO_API } from "../../apiSlice";

export const editProductApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editProduct: builder.mutation({
      query: (newProduct) => {
        return {
          url: `/products/update/${newProduct.id}`,
          method: "PATCH",
          body: newProduct,
        };
      },
      invalidatesTags: ["PRODUCTS"],
    }),
  }),
});

export const { useEditProductMutation } = editProductApiSlice;
