import React, { useRef, useState } from "react";
import { BreadCrumb, Button, Form, GGButton, Input, Modal } from "components";
import { FaCamera, FaPhone } from "react-icons/fa";
import { MdEmail, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut, updateUserState } from "store/slices";
import { Formik } from "formik";
import { useUpdatePasswordMutation, useUploadImageMutation } from "store/api";
import { getCloudFlareImageUrl } from "utils";
import { useDeleteImageMutation } from "store/api/requests/file/deleteImageAPiSlice";
import { useUpdateUserMutation } from "store/api/requests/user/updateUserAPiSlice";
import { toast } from "react-toastify";
import { RiLoader4Line } from "react-icons/ri";
import {
  updatePasswordSchema,
  updateProfileSchema,
} from "components/Forms/schemas";

const Profile = () => {
  const [uploadImage] = useUploadImageMutation();
  const [deleteImage] = useDeleteImageMutation();
  const [loading, setLoading] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const { user } = useSelector((state: any) => state.auth);

  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string>("");
  const [avatar, setAvatar] = useState<Blob | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOutHandler = () => {
    dispatch(logOut());
    navigate("/login");
  };

  const handleImageSelection = (e: any) => {
    const file = e.target.files[0] as File;
    const imageUrl = URL.createObjectURL(file);
    setImage(imageUrl);
    setAvatar(file);
    setModalOpen(true);
  };

  const onImageUpload = async () => {
    try {
      setUploadingImage(true);
      let url = "";
      if (user?.image) {
        const [uploadRes] = await Promise.all([
          uploadImage(avatar).unwrap(),
          deleteImage(user?.image).unwrap(),
        ]);
        url = getCloudFlareImageUrl(uploadRes?.result);
      } else {
        const uploadRes = await uploadImage(avatar).unwrap();
        url = getCloudFlareImageUrl(uploadRes?.result);
      }
      const payload = {
        image: url,
      };
      const res = await updateUser(payload).unwrap();
      if (res.status === "success") {
        dispatch(
          updateUserState({
            user: {
              ...user,
              image: url,
            },
          }),
        );
        setTimeout(() => {
          toast.success("Profile image updated successfully");
          setModalOpen(false);
          setUploadingImage(false);
        }, 2700);
      }
    } catch (error) {
      toast.error(
        "An error occurred while updating your profile, Please try again later",
      );
      setUploadingImage(false);
    }
  };

  const handleUpdateSubmit = async (values: any) => {
    try {
      setLoading(true);
      const { phone, firstName, lastName, city, district, address } = values;
      const payload = {
        phone,
        firstName,
        lastName,
        city,
        district,
        address,
      };
      const res = await updateUser(payload).unwrap();
      setLoading(false);
      if (res.status === "success") {
        dispatch(
          updateUserState({
            user: {
              ...user,
              ...payload,
            },
          }),
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        "An error occurred while updating your profile, Please try again later",
      );
    }
  };

  const handlePasswordUpdateSubmit = async (values: any) => {
    try {
      const { newPassword, currentPassword } = values;
      const payload = {
        userId: user?.docId,
        currentPassword,
        newPassword,
      };
      await updatePassword(payload).unwrap();
    } catch (error) {
      toast.error(
        "An error occurred while updating your password, Please try again later",
      );
    }
  };

  return (
    <div>
      <BreadCrumb />
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        center
        body={
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold">Change Avatar</h3>
            <p className="text-sm">
              Are you sure you want to upload this image as your avatar?.
            </p>
            <div className="flex justify-between gap-5 items-center bg-[#f0f0f5] p-4 px-8 rounded-md">
              <div className="relative rounded-full bg-primary">
                <img
                  className="relative rounded-full w-16 md:w-[110px] h-16 md:h-[110px] border-2 border-linear"
                  alt={user?.firstName ?? "User"}
                  src={
                    user?.image ??
                    `https://eu.ui-avatars.com/api/?name=${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}&size=10`
                  }
                />
              </div>
              <div className="flex items-center gap-0">
                <MdOutlineKeyboardDoubleArrowRight className="text-3xl" />
                <MdOutlineKeyboardDoubleArrowRight className="text-3xl -ml-3" />
              </div>
              <div className="relative rounded-full bg-primary">
                <img
                  className="relative rounded-full w-16 md:w-[110px] h-16 md:h-[110px] border-2 border-linear"
                  alt={user?.firstName ?? "User"}
                  src={
                    image ??
                    `https://eu.ui-avatars.com/api/?name=${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}&size=10`
                  }
                />
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <GGButton
                type="button"
                variant="outlined"
                onClick={() => setModalOpen(false)}
                disable={uploadingImage || loading}
              >
                Cancel
              </GGButton>
              <GGButton
                type="button"
                onClick={onImageUpload}
                loading={uploadingImage || loading}
              >
                Yes, Upload
              </GGButton>
            </div>
          </div>
        }
      />
      <div className="flex flex-col md:flex-row gap-5 w-full">
        <div className="w-full md:w-1/3 flex flex-col gap-5">
          <div className="card flex flex-col items-center justify-center">
            <div className="relative rounded-full w-[110px] h-[110px] bg-primary">
              <img
                className="relative rounded-full w-[110px] h-[110px] border-2 border-linear"
                alt={user?.firstName ?? "User"}
                src={
                  user?.image ??
                  `https://eu.ui-avatars.com/api/?name=${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}&size=10`
                }
              />
              <button
                className="absolute z-10 right-0 bottom-0 h-10 w-10 bg-linear rounded-full border-[3px]
                        border-white border-solid transition hover:bg-primary flex items-center justify-center"
                onClick={() => avatarInputRef.current?.click()}
              >
                {uploadingImage ? (
                  <RiLoader4Line className="animate-spin text-white" />
                ) : (
                  <>
                    <FaCamera className="text-white" />
                    <input
                      type="file"
                      ref={avatarInputRef}
                      hidden
                      onChange={handleImageSelection}
                    />
                  </>
                )}
              </button>
            </div>
            <h4 className="font-poppins font-semibold capitalize text-md mt-2">
              {user?.firstName} {user?.lastName}
            </h4>
            <p className="text-muted text-sm">@{user?.userId}</p>
            <span className="inline-flex  mt-2 capitalize items-center justify-center  h-[22px] rounded bg-linear text-white font-poppins font-medium text-sm px-[12px]">
              {user?.role}
            </span>
          </div>

          <div className="card flex items-center">
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-2 font-poppins">
                <MdEmail className="text-textColor text-xl" />
                <h3 className="text-sm  overflow-hidden text-ellipsis line-clamp-1">
                  {user?.email}
                </h3>
              </div>
              {user?.phone && (
                <div className="flex items-center gap-2 font-poppins ">
                  <FaPhone className="text-textColor text-xl" />
                  {user?.phone}
                </div>
              )}
            </div>
          </div>
          <div className="card flex flex-col justify-center items-center gap-2 ">
            <div className="flex flex-col gap-4 items-center p-2 rounded-md border bg-red-500/10 border-red-500/10 shadow shadow-red-500/10">
              <p>
                Security is of utmost importance. Please remember to log out
                using the button below when you&apos;re finished.
              </p>
              <Button
                onClick={logOutHandler}
                variant="secondary"
                className="text-red-700"
                type="button"
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
        <div className="md:w-2/3 w-full ">
          <div className="card">
            <Formik
              enableReinitialize
              initialValues={{
                email: user?.email ?? "",
                phone: user?.phone ?? "",
                firstName: user?.firstName ?? "",
                lastName: user?.lastName ?? "",
                city: user?.city ?? "",
                district: user?.district ?? "",
                address: user?.address ?? "",
              }}
              validationSchema={updateProfileSchema}
              onSubmit={async (values) => {
                await handleUpdateSubmit(values);
              }}
            >
              {({ handleSubmit, dirty, isValid }) => (
                <Form
                  onSubmit={handleSubmit}
                  title="Update Profile"
                  description="Please update your profile details below"
                >
                  <div className="flex gap-2 flex-col md:flex-row">
                    <Input type="text" name="firstName" label="First Name" />
                    <Input type="text" name="lastName" label="Last Name" />
                  </div>
                  <div className="flex gap-2 flex-col md:flex-row">
                    <Input type="email" name="email" label="Email" disabled />
                    <Input
                      type="tel"
                      name="phone"
                      label="Phone Number"
                      prepend="+256"
                      placeholder="774104154"
                    />
                  </div>
                  <div className="flex gap-2 flex-col md:flex-row">
                    <Input
                      type="text"
                      name="city"
                      label="City"
                      placeholder="e.g Muyenga"
                    />
                    <Input
                      type="text"
                      name="district"
                      label="District"
                      placeholder="e.g Kampala"
                    />
                  </div>
                  <Input
                    type="text"
                    name="address"
                    label="Home Address"
                    placeholder="e.g Muyenga Tank Hill"
                  />
                  <div className="flex justify-center mt-4">
                    <GGButton
                      type="submit"
                      disable={!dirty || !isValid || loading}
                      loading={loading}
                    >
                      Save Changes
                    </GGButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="card mt-4">
            {" "}
            <Formik
              enableReinitialize
              initialValues={{
                newPassword: "",
                currentPassword: "",
                confirmNewPassword: "",
              }}
              validationSchema={updatePasswordSchema}
              onSubmit={async (values) => {
                await handlePasswordUpdateSubmit(values);
              }}
            >
              {({ handleSubmit, dirty, isValid }) => (
                <Form
                  onSubmit={handleSubmit}
                  title="Update Password"
                  description="Please update your password below"
                >
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="currentPassword"
                    label="Current Password"
                    placeholder="********"
                    append={
                      showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )
                    }
                    appendClick={() => setShowPassword(!showPassword)}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="newPassword"
                    label="New Password"
                    placeholder="********"
                    append={
                      showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      )
                    }
                    appendClick={() => setShowPassword(!showPassword)}
                  />
                  <Input
                    type="password"
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    placeholder="********"
                  />

                  <div className="flex justify-center mt-4">
                    <GGButton
                      type="submit"
                      disable={!dirty || !isValid || isLoading}
                      loading={isLoading}
                    >
                      Update Password
                    </GGButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
