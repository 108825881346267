/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from "react";
import { type ToastContent, toast } from "react-toastify";
import { FormControl, GGButton } from "components/UI";
import { Formik } from "formik";
import { useEditOrderMutation, useEditOrderStageMutation } from "store/api";
import { Input, Spinner } from "components/Common";
import { ORDER_STAGES } from "../../constants";
import { type FirebaseDate } from "interfaces";
import { formattedDate } from "utils";

const EditOrder = ({ order, refetch }: { order: any; refetch: any }) => {
  const [editOrder, { isLoading }] = useEditOrderMutation();
  const [editOrderStage, { isLoading: statusIsLoading }] =
    useEditOrderStageMutation();

  const fetchedOrder = order;

  const isShipped = Boolean(
    formattedDate(fetchedOrder.status.shipped).length > 0,
  );
  const isConfirmed = Boolean(
    formattedDate(fetchedOrder.status.confirmed).length > 0,
  );
  const isDelivered = Boolean(
    formattedDate(fetchedOrder.status.delivered).length > 0,
  );

  const orderStageHandler = () => {
    if (isDelivered) {
      return {
        value: "delivered",
        label: "delivered",
      };
    }

    if (isShipped) {
      return {
        value: "shipped",
        label: "Out For Delivery",
      };
    }
    if (isConfirmed) {
      return {
        value: "confirmed",
        label: "confirmed",
      };
    }
    return {
      value: "ordered",
      label: "ordered",
    };
  };

  const initialData = {
    status: fetchedOrder?.orderStatus,
    owner: fetchedOrder?.owner,
    paymentMethod: fetchedOrder?.paymentMethod,

    division: fetchedOrder?.shippingAddress?.division,
    region: fetchedOrder?.shippingAddress?.region,
    shippingEmail: fetchedOrder?.shippingAddress?.shippingEmail,
    shippingName: fetchedOrder?.shippingAddress?.shippingName,
    shoppingPhoneNumber: fetchedOrder?.shippingAddress?.shoppingPhoneNumber,
    shippingPrice: fetchedOrder?.shippingAddress?.shippingPrice,
    shippingMethod: fetchedOrder?.shippingAddress?.shippingMethod,

    orderStage: orderStageHandler().value,
  };

  return (
    <>
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          if (values.orderStage !== initialData.orderStage) {
            await editOrderStage({
              id: fetchedOrder?.docId,
              status: values.orderStage,
            }).unwrap();
          }

          try {
            await editOrder({
              owner: values.owner,
              orderStatus: values.status,
              shippingAddress: {
                division: values.division,
                region: values.region,
                shippingEmail: values.shippingEmail,
                shippingName: values.shippingName,
                shoppingPhoneNumber: values.shoppingPhoneNumber,
                shippingPrice: values.shippingPrice,
                shippingMethod: values.shippingMethod,
              },

              id: fetchedOrder?.docId,
            });
            toast.success("Order updated successfully");
            refetch();
          } catch (error: any) {
            toast.error(error.message as ToastContent<unknown>);
          }
        }}
      >
        {({ handleSubmit, values, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            {" "}
            <div className="grid grid-cols-1 sm:grid-cols-2 my-6 gap-8">
              <div className="flex flex-col gap-4">
                <div>
                  <Input
                    name="owner"
                    label="Customer Name"
                    type="text"
                    value={values.owner}
                  />
                  <Input
                    name="shoppingPhoneNumber"
                    label="Phone Number"
                    type="text"
                    value={values.shoppingPhoneNumber}
                  />
                </div>
                <div>
                  <Input
                    name="createdAt"
                    label="Order Date"
                    type="text"
                    value={formattedDate(
                      fetchedOrder?.createdAt as FirebaseDate,
                    )}
                    disabled
                  />
                </div>
                <div>
                  <FormControl>
                    <Input
                      name="status"
                      label="Order Status"
                      type="select"
                      value={values.status}
                      options={[
                        { label: "PROCESSING", value: "PENDING" },
                        { label: "COMPLETED", value: "COMPLETED" },
                        { label: "CANCELED", value: "CANCELED" },
                      ].map((status) => ({
                        value: status.value,
                        label: status.label,
                      }))}
                    />
                  </FormControl>
                </div>
                <div>
                  <Input
                    name="paymentMethod"
                    label="Payment Method"
                    type="text"
                    value={values.paymentMethod}
                  />
                </div>
                <div>
                  <Input
                    name="orderStage"
                    label="Order Stage"
                    type="select"
                    value={values.orderStage}
                    options={ORDER_STAGES}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div>
                  <Input
                    name="division"
                    label="Division"
                    type="text"
                    value={values.division}
                  />
                  <Input
                    name="region"
                    label="Region"
                    type="text"
                    value={values.region}
                  />

                  <Input
                    name="shippingEmail"
                    label="Email"
                    type="text"
                    value={values.shippingEmail}
                  />

                  <Input
                    name="shippingName"
                    label="Name"
                    type="text"
                    value={values.shippingName}
                  />
                  <Input
                    name="shoppingPhoneNumber"
                    label="Phone Number"
                    type="text"
                    value={values.shoppingPhoneNumber}
                  />

                  <Input
                    name="shippingPrice"
                    label="Shipping Price"
                    type="text"
                    value={values.shippingPrice}
                  />
                </div>
                <div>
                  <Input
                    name="shippingMethod"
                    label="Shipping Method"
                    type="text"
                    value={values.shippingMethod || "N/A"}
                  />
                </div>
              </div>
            </div>
            <div>
              <GGButton
                type="submit"
                disable={statusIsLoading || isLoading || !isValid || !dirty}
              >
                {isLoading || statusIsLoading ? <Spinner /> : "Update"}{" "}
              </GGButton>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditOrder;
