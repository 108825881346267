import React from "react";
import {
  BreadCrumb,
  GGTable,
  REVIEW_COLUMNS,
  ReviewCard,
  ReviewProgressBar,
  SectionTitle,
} from "components";
import { useGetReviewsQuery } from "store/api";

const Reviews = () => {
  const { data, isFetching } = useGetReviewsQuery("reviews");

  const totalReviews = data?.reviews?.length || 0;
  const fiveStarReviews = data?.reviews?.filter(
    (review: any) => review.rating === 5,
  ).length;

  const fiveStarReviewsPercent =
    (data?.reviews?.filter((review: any) => review.rating === 5).length /
      totalReviews) *
    100;
  const fourStarReviewsPercent =
    (data?.reviews?.filter((review: any) => review.rating === 4).length /
      totalReviews) *
    100;
  const threeStarReviewsPercent =
    (data?.reviews?.filter((review: any) => review.rating === 3).length /
      totalReviews) *
    100;
  const twoStarReviewsPercent =
    (data?.reviews?.filter((review: any) => review.rating === 2).length /
      totalReviews) *
    100;
  const oneStarReviewsPercent =
    (data?.reviews?.filter((review: any) => review.rating === 1).length /
      totalReviews) *
    100;

  return (
    <div>
      <BreadCrumb />
      {!isFetching && (
        <div className="flex flex-col flex-1 gap-5 md:gap-[26px]">
          <div className="grid grid-cols-1 gap-y-5 md:gap-y-[26px] xl:grid-cols-6 xl:gap-x-[26px]">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 xl:col-span-4">
              <ReviewCard title="Total" value={totalReviews} />
              <ReviewCard title="Five Star" value={fiveStarReviews} />
            </div>
            <div className="card min-h-[182px] !py-5 flex flex-col justify-between col-span-2">
              <ReviewProgressBar
                review={5}
                percent={fiveStarReviewsPercent || 0}
              />
              <ReviewProgressBar
                review={4}
                percent={fourStarReviewsPercent || 0}
              />
              <ReviewProgressBar
                review={3}
                percent={threeStarReviewsPercent || 0}
              />
              <ReviewProgressBar
                review={2}
                percent={twoStarReviewsPercent || 0}
              />
              <ReviewProgressBar
                review={1}
                percent={oneStarReviewsPercent || 0}
              />
            </div>
          </div>
        </div>
      )}
      <div className="card  flex-1 my-6 px-5 py-5">
        <SectionTitle title="Latest Accepted reviews" />
        <GGTable
          data={data?.reviews || []}
          columns={REVIEW_COLUMNS}
          isLoading={isFetching}
        />
      </div>
    </div>
  );
};

export default Reviews;
