import { GLAM_N_GO_API } from "../../apiSlice";

export const getAllBannersApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getAllBanners: builder.query({
      query: () => ({
        url: "/banners/getAll",
        providesTags: ["BANNERS"],
      }),
    }),
  }),
});

export const { useGetAllBannersQuery } = getAllBannersApiSlice;
