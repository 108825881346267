import React, { useEffect, useState } from "react";
import { GGButton } from "components/UI";
import { BreadCrumb, Loader, SectionTitle, Spinner } from "components/Common";
import { socialMedia } from "data/data";
import { type ToastContent, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditSocialMutation,
  useLazyGetSocialQuery,
  useNewSocialMutation,
} from "store/api";
import { Formik } from "formik";

import SocialForm from "./SocialForm";
import { SocialSchema } from "../schemas";

const AddSocial = ({ isEdit }: { isEdit?: boolean }) => {
  const { socialId } = useParams();
  const navigate = useNavigate();
  const [newSocial, { isLoading }] = useNewSocialMutation();
  const [getSocial, { isFetching }] = useLazyGetSocialQuery();
  const [editSocial, { isLoading: isEditing }] = useEditSocialMutation();
  const [selectedSocial, setSelectedSocial] = useState<any>({});

  const fetchData = async () => {
    try {
      const fetchedData = await getSocial({ id: socialId }).unwrap();
      setSelectedSocial(fetchedData.social);
    } catch (error: any) {
      toast.error(error.message as ToastContent<unknown>);
    }
  };

  useEffect(() => {
    if (isEdit && socialId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, [isEdit, socialId]);

  const onSubmitHandler = async (values: any) => {
    if (isEdit) {
      try {
        await editSocial({
          id: socialId,
          socialIcon: socialMedia.find(
            (social) => social.name === values.social,
          )?.icon,
          social: values.social,
          link: values.link,
          userName: values.userName,
        }).unwrap();
        toast.success("Social updated successfully");
        navigate("../company-socials");
      } catch (error: any) {
        toast.error(error.message as ToastContent<unknown>);
      }
    } else {
      try {
        const data = {
          socialIcon: socialMedia.find(
            (social) => social.name === values.social,
          )?.icon,
          social: values.social,
          link: values.link,
          userName: values.userName,
        };
        await newSocial(data).unwrap();
        toast.success("Social added successfully");
        navigate("../company-socials");
      } catch (error: any) {
        toast.error(error.message as ToastContent<unknown>);
      }
    }
  };

  return (
    <div>
      <BreadCrumb lastName={selectedSocial?.social} />
      <div className="card">
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <SectionTitle title="Add Social" />
            <Formik
              enableReinitialize
              validationSchema={SocialSchema}
              initialValues={{
                social: selectedSocial?.social || "",
                userName: selectedSocial?.userName || "",
                link: selectedSocial?.link || "",
              }}
              onSubmit={async (values, { resetForm }) => {
                await onSubmitHandler(values);
                resetForm();
                navigate("../company-socials");
              }}
            >
              {({ handleSubmit, values, setFieldValue, isValid, dirty }) => (
                <form onSubmit={handleSubmit}>
                  <SocialForm
                    values={values}
                    setFieldValue={setFieldValue}
                    isEdit={isEdit}
                  />
                  <GGButton
                    type="submit"
                    disable={isLoading || isEditing || !isValid || !dirty}
                  >
                    {isLoading || isEditing ? (
                      <Spinner />
                    ) : isEdit ? (
                      "Edit Social"
                    ) : (
                      "Add Social"
                    )}
                  </GGButton>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default AddSocial;
