import { GLAM_N_GO_API } from "../../apiSlice";

export const editStaticPageApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editStaticPage: builder.mutation({
      query: (data) => {
        return {
          url: `/pages/update/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["PAGES"],
    }),
  }),
});

export const { useEditStaticPageMutation } = editStaticPageApiSlice;
