import React, { type FC } from "react";
import { ImageUpload, Input } from "components/Common";
import { type FormComponentProps } from "interfaces";

const CategoryForm: FC<FormComponentProps> = ({
  values,
  setFieldValue,
  isEdit,
}) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 justify-between gap-5 w-full">
    <ImageUpload
      fieldName="image"
      linkName="imageLink"
      setFieldValue={setFieldValue}
      label="Category Image"
      note="required"
      imageUrl={values.image}
    />
    <div className="flex flex-col gap-2 w-full">
      <Input type="text" name="name" label="Category Name" note="required" />
      <Input
        type="textarea"
        rows={6}
        name="description"
        label="Description"
        note="Optional"
      />
      {isEdit && (
        <label
          htmlFor="category-checkbox"
          className="flex w-full cursor-pointer items-start gap-1 mt-2"
        >
          <div className="inline-flex items-center">
            <label
              className="relative flex items-center cursor-pointer rounded-md p-0"
              htmlFor="category-checkbox"
            >
              <input
                id="category-checkbox"
                type="checkbox"
                name="isHidden"
                checked={values.isHidden}
                onChange={(e) => setFieldValue("isHidden", e.target.checked)}
                className={`peer relative appearance-none w-5 h-5 border rounded-md border-border-light cursor-pointer transition-all before:content[''] before:block before:bg-gray-500 before:w-12 before:h-12 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity checked:bg-primary checked:border-primary checked:before:bg-primary hover:before:opacity-0`}
              />
              <span className="text-white absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 pointer-events-none opacity-0 peer-checked:opacity-100 transition-opacity">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
          </div>
          <p className="block antialiased text-base leading-relaxed -mt-1">
            Hide this category from the client application.
          </p>
        </label>
      )}
    </div>
  </div>
);

export default CategoryForm;
