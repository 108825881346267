import { GLAM_N_GO_API } from "../../apiSlice";

export const getUserApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (data) => ({
        url: `/auth/getUser/${data.id}`,
        providesTags: ["USERS"],
      }),
    }),
  }),
});

export const { useGetUserQuery } = getUserApi;
