// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyC23Ylh0WAuAGQvWtqddmP9wN0eEJqtTMQ",
  authDomain: "glam-n-go-development.firebaseapp.com",
  projectId: "glam-n-go-development",
  storageBucket: "glam-n-go-development.appspot.com",
  messagingSenderId: "808258835068",
  appId: "1:808258835068:web:0f62278da9c999623d2ff9",
  measurementId: "G-107Q5H4HJN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
