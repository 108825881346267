import { GLAM_N_GO_API } from "../../apiSlice";

export const getFaqsApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query({
      query: () => ({
        url: "/FAQs/getAll",
        providesTags: ["FAQS"],
      }),
    }),
  }),
});

export const { useGetFaqsQuery } = getFaqsApi;
