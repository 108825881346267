import React from "react";
import { BreadCrumb } from "components";

const Settings = () => {
  return (
    <div>
      <BreadCrumb />
    </div>
  );
};

export default Settings;
