import { GLAM_N_GO_API } from "../../apiSlice";

export const getBannerApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getBanner: builder.query({
      query: (data) => ({
        url: `/banners/${data?.id}`,
        providesTags: ["BANNERS"],
      }),
    }),
  }),
});

export const { useGetBannerQuery, useLazyGetBannerQuery } = getBannerApiSlice;
