import React, { useEffect, useState } from "react";
import { BreadCrumb, DeleteDialog, Loader, OnBoardPreview } from "components";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import {
  useDeleteOnBoardingScreenMutation,
  useGetAllOnBoardingScreensQuery,
} from "store/api";
import { type OnBoardingScreen } from "interfaces";
import { toast } from "react-toastify";

const OnBoardScreens = () => {
  const navigate = useNavigate();
  const { data, isFetching, refetch } =
    useGetAllOnBoardingScreensQuery("screens");
  const modifiedData = (data?.screens as OnBoardingScreen) || {};

  const [deleteOnBoardingScreen, { isLoading: isDeleting }] =
    useDeleteOnBoardingScreenMutation();

  const [onDelete, setOnDelete] = useState(false);

  const sortedData = isFetching
    ? []
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.values(modifiedData).sort((a, b) => a.order - b.order);

  const onDeleteHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    const selectedScreen = searchParams.get("screen");
    const selectedScreenImage = searchParams.get("img");

    try {
      await deleteOnBoardingScreen({
        name: selectedScreen as string,
        image: selectedScreenImage as string,
      })
        .unwrap()
        .then(async () => {
          await refetch();
        });
      navigate({
        search: "",
      });
      setOnDelete(false);
      toast.success("Screen deleted successfully");
    } catch (e) {
      toast.error("Failed to delete screen");
    }
  };

  const cancelHandler = () => {
    setOnDelete(false);
    navigate({
      search: "",
    });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch();
  }, []);

  return (
    <div>
      <BreadCrumb />
      <div className="card">
        {isFetching ? (
          <Loader showText />
        ) : (
          <>
            <div className="flex justify-end my-3">
              <button
                onClick={() => {
                  navigate("add-onboard-screen");
                }}
                className=" bg-primary text-white flex justify-center items-center rounded-full px-4 py-2 text-sm font-semibold mt-2 hover:bg-primary-shade transition-all duration-300
                "
              >
                <FaPlus />
                Add New
              </button>
            </div>
            <DeleteDialog
              show={onDelete}
              itemName="OnBoarding Screen"
              actionName="Delete"
              onCancel={cancelHandler}
              onConfirm={onDeleteHandler}
              isLoading={isDeleting}
            />
            <div
              className={`flex flex-col sm:flex-row flex-wrap gap-2 ${sortedData.length === 0 ? "justify-center" : ""}`}
            >
              {sortedData.length === 0 ? (
                <p>
                  {" "}
                  You haven&apos;t created any onboarding screen yet, Click
                  button above to create one
                </p>
              ) : (
                sortedData.map((screen, idx) => (
                  <OnBoardPreview
                    key={`${screen.title}-${idx}`}
                    title={screen.title}
                    description={screen.description}
                    image={screen.image}
                    bgColor={screen.color}
                    showActions
                    onEdit={() => {
                      navigate(screen.name);
                    }}
                    onDelete={() => {
                      setOnDelete(true);
                      const queryParams = new URLSearchParams();
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                      queryParams.set("screen", screen.name as string);
                      queryParams.set("img", screen.image);

                      navigate({
                        search: `?${queryParams.toString()}`,
                      });
                    }}
                  />
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OnBoardScreens;
