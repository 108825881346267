import React from "react";
import LogoHorizontal from "../../../assets/images/logoHorizontal.svg";
import "./style.css";

const StaticPageTemplate = ({ page }: { page: any }) => {
  return (
    <>
      <div className="page-header">
        <div className="">
          <img src={LogoHorizontal} className="w-[100px]" />
        </div>
        <h1 className="text-white font-poppins font-semibold text-sm">
          {page.title}
        </h1>
      </div>
      <div className="page-wrapper">
        <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
      </div>
    </>
  );
};

export default StaticPageTemplate;
