import React from "react";

import { FaStar } from "react-icons/fa";
import GGProgressbar from "../../UI/GGProgressBar";

const ReviewProgressBar = ({
  review,
  percent,
}: {
  review: number;
  percent: number;
}) => {
  return (
    <div className="flex items-center gap-2.5 px-5">
      <span className="flex items-center gap-1 label-text leading-none w-[30px] font-poppins font-medium">
        {review}
        <FaStar className="text-yellow" />
      </span>
      <div className="flex-1">
        <GGProgressbar progress={Number(percent)} />
      </div>
      <span className="!text-header label-text w-[42px] text-right font-poppins font-medium">
        {percent.toFixed(1)}%
      </span>
    </div>
  );
};

export default ReviewProgressBar;
