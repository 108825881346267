import { GLAM_N_GO_API } from "../../apiSlice";

export const editBannerApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editBanner: builder.mutation({
      query: (data) => {
        return {
          url: `/banners/update/${data.id}`,
          method: "PATCH",
          body: data.banner,
        };
      },
      invalidatesTags: ["BANNERS"],
    }),
  }),
});

export const { useEditBannerMutation } = editBannerApiSlice;
