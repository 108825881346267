import React from "react";
import { Outlet } from "react-router-dom";
import { MdOutlineShoppingCartCheckout, MdTrolley } from "react-icons/md";
import { BsBarChartLineFill } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";

const AuthenticationPage = () => {
  const ListItems = [
    { name: "Manage orders", icon: <MdOutlineShoppingCartCheckout /> },
    { name: "Manage Sales", icon: <BsBarChartLineFill /> },
    { name: "Manage products", icon: <MdTrolley /> },
    { name: "Get reports", icon: <TbReportSearch /> },
  ];

  return (
    <div className="flex items-center">
      <div className="flex items-center justify-center h-screen w-1/2  bg-glamBg bg-no-repeat bg-center bg-cover max-sm:hidden">
        <div className={` flex flex-col items-start justify-center `}>
          <div className="bg-glamLogo h-10 w-10" />
          <div className="w-20 pb-4"></div>
          <h1 className="text-[24px] text-white font-bold font-poppins   ">
            Welcome to Glam n&apos; Go
          </h1>
          <div className="mt-4">
            <ul className="list-none">
              {ListItems.map((item) => (
                <li
                  key={Math.random()}
                  className="text-4 text-lg text-white font-medium font-poppins capitalize flex gap-1 items-center"
                >
                  <div className="first:w-6 first:h-6">{item.icon}</div>

                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="w-1/2 lg:max-[1280]:px-0 max-sm:w-full max-sm:flex px-4 max-sm:flex-col max-sm:justify-center max-sm:h-screen max-sm:bg-primary ">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthenticationPage;
