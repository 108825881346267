import { GLAM_N_GO_API } from "../../apiSlice";

export const removeProductFromOrderApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    removeProductFromOrder: builder.mutation({
      query: (product) => ({
        url: `/orders/removeProductFromOrder/${product.productId}`,
        method: "PATCH",
        body: product,
      }),
      invalidatesTags: ["ORDER"],
    }),
  }),
});

export const { useRemoveProductFromOrderMutation } =
  removeProductFromOrderApiSlice;
