import { GLAM_N_GO_API } from "../../apiSlice";

export const editFaqApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editFaq: builder.mutation({
      query: (data) => {
        return {
          url: `/FAQs/edit/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["FAQS"],
    }),
  }),
});

export const { useEditFaqMutation } = editFaqApiSlice;
