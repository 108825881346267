import { GLAM_N_GO_API } from "../../apiSlice";

export const subCategoriesApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubCategories: builder.query({
      query: () => ({
        url: `/subCategories/getAll`,
        providesTags: ["SUB_CATEGORIES"],
      }),
    }),
    getSubCategoryById: builder.query({
      query: (subCategory) => ({
        url: `/subCategories/${subCategory}`,
        providesTags: ["SUB_CATEGORIES"],
      }),
    }),
    addNewSubCategory: builder.mutation({
      query: (data) => ({
        url: `/subCategories/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SUB_CATEGORIES"],
    }),
    updateSubCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/subCategories/update/${id}`,
        method: "PATCH",
        body: { ...data },
      }),
      invalidatesTags: ["SUB_CATEGORIES"],
    }),

    deleteSubCategory: builder.mutation({
      query: (id) => ({
        url: `/subCategories/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SUB_CATEGORIES"],
    }),
  }),
});

export const {
  useGetAllSubCategoriesQuery,
  useGetSubCategoryByIdQuery,
  useLazyGetSubCategoryByIdQuery,
  useUpdateSubCategoryMutation,
  useDeleteSubCategoryMutation,
  useAddNewSubCategoryMutation,
} = subCategoriesApiSlice;
