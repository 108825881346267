import { GLAM_N_GO_API } from "../../apiSlice";

export const addSubCategoryApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    addSubCategory: builder.mutation({
      query: (subCategory) => {
        return {
          url: "/subCategories/create",
          method: "POST",
          body: subCategory,
        };
      },
      invalidatesTags: ["CATEGORIES"],
    }),
  }),
});

export const { useAddSubCategoryMutation } = addSubCategoryApiSlice;
