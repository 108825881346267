import { GLAM_N_GO_API } from "../../apiSlice";

export const newCategoryApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    newCategory: builder.mutation({
      query: (newCategory) => {
        return {
          url: "/categories/create",
          method: "POST",
          body: newCategory,
        };
      },
      invalidatesTags: ["CATEGORIES"],
    }),
  }),
});

export const { useNewCategoryMutation } = newCategoryApiSlice;
