import { GLAM_N_GO_API } from "../../apiSlice";

export const getAllReviewsApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query({
      query: () => ({
        url: "/reviews/getAll",
        providesTags: ["REVIEWS"],
      }),
    }),
  }),
});

export const { useGetReviewsQuery } = getAllReviewsApiSlice;
