import { GLAM_N_GO_API } from "../../apiSlice";

export const getProductsApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => ({
        url: "/products/getAll",
        providesTags: ["PRODUCTS"],
      }),
    }),
  }),
});

export const { useGetProductsQuery } = getProductsApi;
