import React from "react";
import {
  DashboardSummaryCard,
  GGTable,
  ORDER_COLUMNS,
  DASHBOARD_PRODUCT_COLUMNS,
  ProductCategoryCard,
  Loader,
} from "components";
import {
  useGetAllCategoriesQuery,
  useGetMyStoreQuery,
  useGetOrdersQuery,
  useGetProductsQuery,
  useGetStoreStatsQuery,
} from "store/api";
import { Link } from "react-router-dom";
import { formattedPrice } from "utils";
import { UserRoles, type OrderType } from "interfaces";
import { useSelector } from "react-redux";

const AdminDashboard = () => {
  const { user } = useSelector((state: any) => state.auth);
  const { data: stats, isFetching: statIsloading } =
    useGetStoreStatsQuery("storeStats");
  const { data, isFetching } = useGetProductsQuery("products");
  const { data: categoriesData, isFetching: categoriesLoading } =
    useGetAllCategoriesQuery("categories");
  const products =
    data?.products.filter((product: any) => product.storeId !== undefined) ??
    [];

  const { data: store } = useGetMyStoreQuery("store");

  const myStore = store?.stores[0];
  const { docId } = myStore || { docId: "" };

  const formattedData = products?.map((pdt: any) => {
    const category = categoriesData?.categories?.find(
      (cat: any) => cat.docId === pdt.category,
    );
    return {
      ...pdt,
      category: category?.name,
    };
  });

  const { data: allOrders, isFetching: loadingOrders } = useGetOrdersQuery({
    id: docId,
  });
  const ordersData =
    allOrders?.orders.filter((order: OrderType) => order.orderNumber) ?? [];

  const formattedOrders = ordersData.map((order: any) => {
    return {
      ...order,
      amount:
        user?.role === UserRoles.Admin ? order.quotedPrice : order.productPrice,
      quantity:
        user?.role === UserRoles.Admin
          ? order.orderDetails.reduce(
              (acc: number, item: any) => acc + item.quantity,
              0,
            )
          : order.quantity,
    };
  });

  const summary = [
    {
      title: "total sales (UGX)",
      value: formattedPrice(stats?.totalSales as string, "UGX"),
    },
    {
      title: "total orders",
      value: stats?.totalOrders || 0,
    },
    {
      title: "total products",
      value: stats?.totalProducts || 0,
    },
    {
      title: "total Customers",
      value: stats?.totalCustomers || 0,
    },
  ];

  return (
    <div className="mb-8">
      <div className=" grid grid-cols-4 max-lg:grid-cols-2 max-md:grid-cols-1 gap-4 w-full py-4">
        {statIsloading ? (
          <div className=" col-span-4">
            <Loader />
          </div>
        ) : (
          summary.map((item) => (
            <DashboardSummaryCard
              title={item.title}
              value={item.value}
              key={Math.random()}
            />
          ))
        )}
      </div>
      <div className="flex items-start justify-between gap-4 mt-6 max-md:flex-wrap max-md:px-2 max-md:my-2">
        <div className="w-[60%] flex flex-col justify-start max-2xl:w-[70%] max-md:flex-1 card">
          <div className="flex items-center justify-between mb-6">
            <h3 className="font-poppins font-semibold text-[16px]">Products</h3>
            <div className="flex gap-6">
              {formattedData?.length > 0 && (
                <Link
                  to="../products"
                  className="font-poppins font-semibold text-sm text-linear hover:cursor-pointer hover:underline"
                >
                  See all
                </Link>
              )}
            </div>
          </div>
          <GGTable
            data={formattedData?.slice(0, 5)}
            columns={DASHBOARD_PRODUCT_COLUMNS}
            isLoading={isFetching || categoriesLoading}
            hideNavigation
          />
        </div>
        <div className="w-[400px] max-2xl:w-[250px] flex-1 card">
          <div className="flex gap-6 justify-between">
            <div className="flex gap-2 mb-6 ">
              <h3 className="font-poppins font-semibold capitalize text-[16px]">
                product categories
              </h3>
            </div>
            {categoriesData?.categories?.length > 0 && (
              <Link
                to="../product-categories"
                className="font-poppins font-semibold text-sm text-linear hover:cursor-pointer hover:underline"
              >
                See all
              </Link>
            )}
          </div>
          <div className="flex justify-center flex-wrap gap-4">
            {categoriesData?.categories
              ?.slice(0, 6)
              .map((cat: any) => (
                <ProductCategoryCard key={Math.random()} category={cat} />
              ))}
          </div>
        </div>
      </div>
      <div className="flex items-start gap-4 flex-wrap justify-between max-xl:gap-4 mt-6">
        <div className="w-[60%] flex flex-col justify-start max-2xl:w-[70%] card max-md:flex-1">
          <div className="flex items-center justify-between mb-6">
            <h3 className="font-poppins font-semibold text-[16px]">
              Product Orders
            </h3>
            <div className="flex gap-6">
              {formattedOrders?.length > 0 && (
                <Link
                  to="../orders"
                  className="font-poppins font-semibold text-sm text-linear hover:cursor-pointer hover:underline"
                >
                  See all
                </Link>
              )}
            </div>
          </div>
          <GGTable
            data={formattedOrders.slice(0, 5)}
            columns={ORDER_COLUMNS.filter(
              (col) => col.accessor !== "createdAt",
            )}
            isLoading={loadingOrders}
            hideNavigation
          />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
