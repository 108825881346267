import React, { useState } from "react";
import {
  useDeleteOrderMutation,
  useGetMyStoreQuery,
  useGetOrdersQuery,
  useGetOrderStatisticsQuery,
} from "store/api";
import {
  BreadCrumb,
  DashboardSummaryCard,
  DeleteDialog,
  GGTable,
  ORDER_COLUMNS,
} from "components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { UserRoles } from "interfaces";
import { getSortedData } from "utils";

const Orders = () => {
  const { data: store } = useGetMyStoreQuery("store");

  const myStore = store?.stores[0];
  const { docId } = myStore || { docId: "" };
  const { data, isFetching, refetch } = useGetOrdersQuery({ id: docId });
  const { data: stats, isFetching: isFetchingStats } =
    useGetOrderStatisticsQuery("statistics");
  const { user } = useSelector((state: any) => state.auth);
  const [deleteOrder, { isLoading }] = useDeleteOrderMutation();
  const ordersData = data?.orders ?? [];
  const navigate = useNavigate();

  const [onDelete, setOnDelete] = useState(false);

  const summary = [
    { title: "new orders", value: stats?.pendingOrders || 0 },
    { title: "canceled orders", value: stats?.cancelledOrders || 0 },
    { title: "completed orders", value: stats?.completedOrders || 0 },
    { title: "delivered orders", value: stats?.deliveredOrders || 0 },
  ];

  const onDeleteHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    const selectedProductId = searchParams.get("orderId");
    try {
      await deleteOrder({ id: selectedProductId as string })
        .unwrap()
        .then(async () => {
          await refetch();
        });
      navigate({
        search: "",
      });
      setOnDelete(false);
      toast.success("Order deleted successfully");
    } catch (e) {
      toast.error("Failed to delete order");
    }
  };

  const cancelHandler = () => {
    setOnDelete(false);
    navigate({
      search: "",
    });
  };

  const formattedOrders = ordersData
    ?.filter((o: any) => o?.orderNumber !== undefined)
    .map((order: any) => ({
      ...order,
      amount:
        user?.role === UserRoles.Admin ? order.quotedPrice : order.productPrice,
      quantity:
        user?.role === UserRoles.Admin
          ? order.orderDetails.reduce(
              (acc: number, item: any) => acc + item.quantity,
              0,
            )
          : order.quantity,
    }));

  const sortedOrders = getSortedData(
    (formattedOrders.filter((o: any) => o?.createdAt) as any[]) || [],
    "createdAt",
  );

  return (
    <>
      <div>
        <BreadCrumb />
        {!isFetchingStats && (
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center justify-around w-full py-4">
            {summary.map((item) => (
              <DashboardSummaryCard
                title={item.title}
                value={item.value}
                key={Math.random()}
              />
            ))}
          </div>
        )}
      </div>
      <div className="w-full px-0 py-2 mt-8 card">
        <DeleteDialog
          show={onDelete}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onConfirm={onDeleteHandler}
          onCancel={cancelHandler}
          isLoading={isLoading}
          itemName="order"
          actionName="Delete"
        />
        <GGTable
          data={sortedOrders ?? []}
          columns={ORDER_COLUMNS}
          isLoading={isFetching}
          searchInputPlaceholder="Search orders..."
          onDeleteHandler={(selectedOrder) => {
            setOnDelete(true);
            const queryParams = new URLSearchParams();
            if (user?.role === UserRoles.Admin) {
              queryParams.set("orderId", selectedOrder.docId as string);
              navigate({
                search: `?${queryParams.toString()}`,
              });
            } else {
              queryParams.set("orderId", selectedOrder.orderId as string);
              navigate({
                search: `?${queryParams.toString()}`,
              });
            }
          }}
          onEditHandler={(selectedOrder) => {
            if (user?.role === UserRoles.Admin) {
              navigate(`./${selectedOrder.docId}?edit=true`);
            } else {
              navigate(`./${selectedOrder.orderId}?edit=true`);
            }
          }}
          onViewHandler={(selectedOrder) => {
            if (user?.role === UserRoles.Admin) {
              navigate(`./${selectedOrder.docId}`);
            } else {
              navigate(`./${selectedOrder.orderId}`);
            }
          }}
          onRefresh={refetch}
        />
      </div>
    </>
  );
};

export default Orders;
