import { GLAM_N_GO_API } from "../../apiSlice";

export const getShopByIdApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getShopById: builder.query({
      query: (shop) => ({
        url: `/stores/${shop.id}`,
        providesTags: ["SHOP"],
      }),
    }),
  }),
});

export const { useGetShopByIdQuery, useLazyGetShopByIdQuery } = getShopByIdApi;
