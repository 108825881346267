import { GLAM_N_GO_API } from "../../apiSlice";

export const newProductApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    newProduct: builder.mutation({
      query: (newProduct) => {
        return {
          url: "/products/create",
          method: "POST",
          body: newProduct,
        };
      },
      invalidatesTags: ["PRODUCTS"],
    }),
  }),
});

export const { useNewProductMutation } = newProductApiSlice;
