import { GLAM_N_GO_API } from "../../apiSlice";

export const newShopApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    newShop: builder.mutation({
      query: (newShop) => {
        return {
          url: "/stores/create",
          method: "POST",
          body: newShop,
        };
      },
      invalidatesTags: ["SHOPS"],
    }),
  }),
});

export const { useNewShopMutation } = newShopApiSlice;
