import React from "react";
import { GGButton, Input } from "components";
import { shareMessageSchema } from "components/Forms/schemas";
import { Formik } from "formik";
import {
  useEditShareMessageMutation,
  useGetShareMessageQuery,
} from "store/api";
import { toast } from "react-toastify";

const ShareMessage = () => {
  const { data } = useGetShareMessageQuery("shareMessage");
  const [editShareMessage, { isLoading }] = useEditShareMessageMutation();

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={shareMessageSchema}
        initialValues={{
          message:
            data?.shareMessage?.body ||
            "Take a look at this awesome PRODUCT_PLACEHOLDER from PRODUCT_SHOP here at glamngo",
          callingNumber: data?.shareMessage?.callingNumber || "",
          whatsappNumber: data?.shareMessage?.whatsAppNumber || "",
        }}
        onSubmit={async (values) => {
          await editShareMessage({
            body: values.message,
            callingNumber: values.callingNumber,
            whatsAppNumber: values.whatsappNumber,
          });
          toast.success("Share message updated successfully");
        }}
      >
        {({ handleSubmit, isValid, dirty, values }) => (
          <form onSubmit={handleSubmit}>
            <Input
              name="message"
              label="Share Message"
              type="textarea"
              placeholder="Enter message here"
              note="This message will be used when sharing products on social media"
              value={values.message}
            />
            <Input
              name="callingNumber"
              label="Calling Number"
              type="text"
              placeholder="Enter calling number here"
              note="Number to use on the call to order button on the app"
              value={values.callingNumber}
            />
            <Input
              name="whatsappNumber"
              label="Whatsapp Number"
              type="text"
              placeholder="Enter whatsapp number here"
              note="Number to use on the whatsapp button on the app"
              value={values.whatsappNumber}
            />
            <GGButton type="submit" disable={isLoading || !isValid || !dirty}>
              {isLoading ? "Updating..." : "Update"}
            </GGButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ShareMessage;
