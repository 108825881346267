import { GLAM_N_GO_API } from "../../apiSlice";

export const getStoreDetailsApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getStoreDetails: builder.query({
      query: (shop) => ({
        url: `/stores/getStoreOwner/${shop.id}`,
        providesTags: ["SHOP"],
      }),
    }),
  }),
});

export const { useGetStoreDetailsQuery } = getStoreDetailsApiSlice;
