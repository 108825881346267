// /stores/getStoreStats

import { GLAM_N_GO_API } from "../../apiSlice";

export const getStoreStatsApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getStoreStats: builder.query({
      query: () => ({
        url: "/stores/getStoreStats",
        providesTags: ["SHOP"],
      }),
    }),
  }),
});

export const { useGetStoreStatsQuery } = getStoreStatsApiSlice;
