import { store } from "store";
import { GLAM_N_GO_API } from "../../apiSlice";
import { UserRoles, type UserType } from "interfaces";

export const getOrdersApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (data) => {
        const state = store.getState().auth;

        const { role } = state.user as UserType;
        const url =
          role === UserRoles.Vendor
            ? `/orders/getStoreOrders/${data.id}`
            : `/orders/getAll`;
        return {
          url,
          providesTags: ["ORDERS"],
        };
      },
    }),
  }),
});

export const { useGetOrdersQuery } = getOrdersApi;
