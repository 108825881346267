import { GLAM_N_GO_API } from "../../apiSlice";

export const editShopApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    editShop: builder.mutation({
      query: ({ id, ...data }) => {
        return {
          url: `/stores/update/${id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["SHOP", "SHOPS"],
    }),
  }),
});

export const { useEditShopMutation } = editShopApiSlice;
