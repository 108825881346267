import { GLAM_N_GO_API } from "../../apiSlice";

export const getAllCategoriesApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => ({
        url: "/categories/getAll",
        providesTags: ["CATEGORIES"],
      }),
    }),
  }),
});

export const { useGetAllCategoriesQuery } = getAllCategoriesApi;
