import React from "react";
import { BreadCrumb, FaqCard, Loader } from "components";

import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetFaqsQuery } from "store/api";

const Faqs = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useGetFaqsQuery("faqs");
  const faqs = data?.faqs;

  return (
    <div>
      <div>
        <BreadCrumb />
      </div>
      <div className="bg-white p-5 rounded-md">
        <div className="flex justify-end my-3">
          <button
            onClick={() => {
              navigate("add-faq");
            }}
            className=" bg-primary text-white flex justify-center items-center rounded-full px-4 py-2 text-sm font-semibold mt-2 hover:bg-primary-shade transition-all duration-300
            "
          >
            {" "}
            <FaPlus />
            Add New
          </button>
        </div>
        <div>
          {isFetching ? (
            <Loader />
          ) : (
            faqs?.map((faq: any) => (
              <FaqCard
                key={faq.docId}
                id={faq.docId}
                question={faq.FAQ.question}
                answer={faq.FAQ.answer}
              />
            ))
          )}
          {faqs?.length === 0 && (
            <div className="flex justify-center items-center h-20 text-md font-poppins">
              No Faqs yet! Click on Add New to create a new Faq
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
