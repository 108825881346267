import React from "react";
import { Input } from "components/Common";
import { Formik } from "formik";

import { GGButton } from "components/UI";
import {
  useAddNewDeliveryLocationMutation,
  useUpdateDeliveryLocationMutation,
} from "store/api";
import { toast } from "react-toastify";

const DeliveryCostsForm = ({
  regions,
  selectedLocation,
  isEdit,
  setAction,
  refetchData,
}: {
  regions?: any;
  selectedLocation?: any;
  isEdit?: boolean;
  setAction?: any;
  refetchData?: any;
}) => {
  const [addNewDeliveryLocation, { isLoading }] =
    useAddNewDeliveryLocationMutation();

  const [updateDeliveryLocation, { isLoading: isUpdating }] =
    useUpdateDeliveryLocationMutation();
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          cost: selectedLocation?.cost || "",
          region: selectedLocation?.region || "",
          area: selectedLocation?.area || "",
        }}
        onSubmit={async (values) => {
          if (isEdit) {
            await updateDeliveryLocation({
              id: selectedLocation?.docId,
              ...values,
            }).unwrap();
            setAction(false);
            toast.success("Delivery location updated successfully");
            refetchData();
          } else {
            await addNewDeliveryLocation(values).unwrap();
            setAction(false);
            toast.success("Delivery location added successfully");
            refetchData();
          }
        }}
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Input
              name="region"
              type="select"
              label="Region"
              value={values.region}
              options={regions?.map((reg: string) => ({
                label: reg,
                value: reg,
              }))}
              selectLabel="Region"
            />
            <Input
              name="area"
              value={values.area}
              label="Area"
              type="text"
              required
              placeholder="Enter Area"
            />
            <Input
              name="cost"
              value={values.cost}
              label="Delivery Cost"
              type="number"
              required
              placeholder="Enter Delivery Cost"
            />
            <div className="flex justify-end mt-2 gap-2">
              <GGButton
                type="button"
                variant="outlined"
                onClick={() => setAction(false)}
                disable={isLoading}
              >
                Cancel
              </GGButton>
              <GGButton
                type="submit"
                disable={isLoading || isUpdating}
                loading={isLoading || isUpdating}
              >
                {isEdit ? "Save Changes " : "Add "} Delivery Location
              </GGButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default DeliveryCostsForm;
