import React from "react";
import { type ToastContent, toast } from "react-toastify";
import { FormControl, GGButton } from "components/UI";
import { Formik } from "formik";
import Select from "react-select";
import { useAddProductOnOrderMutation, useGetProductsQuery } from "store/api";
import { Input, Spinner } from "components/Common";
import { ProductColors, productSizes } from "data/data";
import { useEditOrderProductMutation } from "store/api/requests/order/editOrderProductApiSlice";

const OrderProduct = ({
  order,
  refetch,
  close,
  productDetails,
  isEdit,
}: {
  order?: any;
  refetch?: any;
  close: any;
  productDetails?: any;
  isEdit?: boolean;
}) => {
  const { data: products } = useGetProductsQuery("products");
  const [addProductOnOrder, { isLoading }] = useAddProductOnOrderMutation();
  const [editOrderProduct, { isLoading: isEditing }] =
    useEditOrderProductMutation();

  const fetchedOrder = order;
  const setProduct = products?.products?.find(
    (product: any) => product.docId === productDetails?.productId,
  );

  const initialData = {
    product: { value: setProduct?.docId, label: setProduct?.name } || "",
    quantity: productDetails?.quantity || "",
    size: productDetails?.size?.toUpperCase() || "",
    color: productDetails?.color?.toUpperCase() || "",
  };

  return (
    <>
      <Formik
        initialValues={initialData}
        enableReinitialize
        onSubmit={async (values) => {
          if (isEdit) {
            try {
              await editOrderProduct({
                productId: values.product.value,
                quantity: values.quantity,
                size: values.size,
                color: values.color,
                id: fetchedOrder?.docId,
              });
              toast.success("Order updated successfully");
              close();
              refetch();
            } catch (error: any) {
              toast.error(error.message as ToastContent<unknown>);
            }
          } else {
            try {
              await addProductOnOrder({
                productId: values.product.value,
                quantity: values.quantity,
                size: values.size,
                color: values.color,
                id: fetchedOrder?.docId,
              });
              toast.success("Order updated successfully");
              close();
              refetch();
            } catch (error: any) {
              toast.error(error.message as ToastContent<unknown>);
            }
          }
        }}
      >
        {({ handleSubmit, values, isValid, dirty, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            {" "}
            <div className="grid grid-cols-1 sm:grid-cols-2 my-6 gap-8">
              <div>
                <FormControl>
                  <span className="block field-label mb-2.5">Product</span>
                  <Select
                    isSearchable
                    name="product"
                    value={values.product}
                    onChange={(selectedOption) => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      setFieldValue("product", selectedOption);
                    }}
                    isDisabled={isEdit}
                    options={products?.products?.map(
                      (product: any) =>
                        ({
                          value: product.id,
                          label: product.name,
                        }) as any,
                    )}
                    className="basic"
                    placeholder="Select Product"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 8,
                      colors: {
                        ...theme.colors,
                        primary25: "#f4e8eb",
                        primary: "#DD4470",
                        primary50: "#f4e8eb",
                      },
                    })}
                  />
                </FormControl>

                <Input
                  name="quantity"
                  label="Quantity"
                  type="number"
                  placeholder="Enter Quantity"
                  note="Enter the quantity of the product you want to add to the order."
                  value={values.quantity}
                />
              </div>
              <div>
                <Input
                  name="size"
                  label="Size"
                  type="select"
                  placeholder="Select Size"
                  note="Select the size of the product you want to add to the order."
                  value={values.size}
                  options={productSizes}
                />
                <Input
                  name="color"
                  label="Color"
                  type="select"
                  placeholder="Select Color"
                  note="Select the color of the product you want to add to the order."
                  value={values.color}
                  options={ProductColors}
                />
              </div>
            </div>
            <div className="flex justify-start gap-2">
              <GGButton type="submit" disable={!isValid || !dirty}>
                {isLoading || isEditing ? (
                  <Spinner />
                ) : !isEdit ? (
                  "Add product"
                ) : (
                  "Save Changes"
                )}
              </GGButton>
              <GGButton type="button" variant="outlined" onClick={close}>
                Cancel
              </GGButton>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default OrderProduct;
