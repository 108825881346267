import {
  type BaseQueryApi,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { type RootState } from "..";
import { logOut } from "store/slices";
import { toast } from "react-toastify";

export const getBaseURL = () => {
  if (
    process.env.NODE_ENV === "production" &&
    window.location.hostname === "glamngo.ug"
  ) {
    return "https://api-azht7eypnq-uc.a.run.app";
  }
  return "https://api-nsftsqg7ma-uc.a.run.app";
};

const GLAM_BASE_QUERY = fetchBaseQuery({
  baseUrl: getBaseURL(),
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth;
    if (accessToken != null) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (
  args: Parameters<typeof GLAM_BASE_QUERY>[0],
  api: BaseQueryApi,
  extraOptions: Parameters<typeof GLAM_BASE_QUERY>[2],
) => {
  const result = await GLAM_BASE_QUERY(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // Dispatch logout action
    api.dispatch(logOut());
    toast.error("Session expired, Please login again");
  }

  return result;
};

export const GLAM_N_GO_API = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  keepUnusedDataFor: 0.0001,
  tagTypes: [
    "PRODUCTS",
    "SHOPS",
    "SHOP",
    "USERS",
    "ORDERS",
    "CATEGORIES",
    "REVIEWS",
    "CARTS",
    "FAQS",
    "PAGES",
    "SOCIALS",
    "BANNERS",
    "SCREENS",
    "SHARE_MESSAGE",
    "ORDER",
    "D_LOCATIONS",
    "D_LOCATION",
    "SUB_CATEGORIES",
  ],
});
