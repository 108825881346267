import React from "react";
import { BreadCrumb, HelpForm } from "components";
import Image from "../../assets/images/support-2355701_1280.jpg";

const Help = () => {
  return (
    <div>
      <BreadCrumb />
      <div className="w-full flex justify-center items-center">
        <div className="grid grid-cols-1 gap-5 overflow-hidden  rounded-xl bg-slate-100 shadow-lg sm:grid-cols-2 ">
          <div>
            <img src={Image} className="w-full h-full" />
          </div>
          <div className="p-5">
            <h1 className="text-3xl font-bold text-primary">
              We cant wait to hear <br></br> from you!
            </h1>
            <HelpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
