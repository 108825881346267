import React from "react";
import { BreadCrumb, Loader } from "components";
import PageTemplate from "./PageTemplate";
import { useGetStaticPagesQuery } from "store/api";

const StaticPages = () => {
  const { data, isFetching } = useGetStaticPagesQuery("staticPages");
  const pages = data?.pages;

  return (
    <div>
      <div>
        <BreadCrumb />
      </div>
      <div>
        {isFetching ? (
          <Loader showText />
        ) : (
          pages?.map((page: any) => (
            <PageTemplate
              key={page.id}
              page={page.title}
              placeholder="Type here"
              value={page.content}
              id={page.docId}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default StaticPages;
