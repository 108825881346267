import React from "react";
import { BreadCrumb, GGTable, Loader, USERS_COLUMNS } from "components";

import { useGetUsersQuery } from "store/api";
import { useNavigate } from "react-router-dom";
import { getSortedData } from "utils";

const Users = () => {
  const { data, isFetching, refetch } = useGetUsersQuery("users");
  const navigate = useNavigate();

  const users = data?.users;
  const sortedData = getSortedData(users as any[], "createdAt");
  return (
    <div>
      <BreadCrumb />
      <div className="card">
        {isFetching ? (
          <div className="sm:col-span-2 md:col-span-3">
            <Loader />
          </div>
        ) : (
          <GGTable
            onViewHandler={({ docId }: { docId: string }) => {
              navigate(docId);
            }}
            onAddNewHandler={() => navigate("new")}
            data={sortedData || []}
            columns={USERS_COLUMNS}
            onRefresh={refetch}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
