import React from "react";
import { Loader, StaticPageTemplate } from "components";
import { useGetStaticPagesQuery } from "store/api";

const AboutUs = () => {
  const { data, isFetching } = useGetStaticPagesQuery("staticPages");
  const page = data?.pages.find((item: any) => item.pageId === "ABOUT_US_PAGE");

  return (
    <div>
      {isFetching ? <Loader showText /> : <StaticPageTemplate page={page} />}
    </div>
  );
};

export default AboutUs;
