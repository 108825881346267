import { DeleteDialog } from "components/Common";
import { type BannerProps } from "interfaces";
import React from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteBannerMutation } from "store/api/requests/banners/deleteBannerApiSlice";

const BannerCard = ({ banner }: { banner: BannerProps }) => {
  const navigate = useNavigate();
  const [onDelete, setOnDelete] = React.useState(false);
  const [deleteBanner, { isLoading: isDeleting }] = useDeleteBannerMutation();

  const onDeleteHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    const selectedBanner = searchParams.get("bannerId");
    try {
      await deleteBanner({ id: selectedBanner as string }).unwrap();

      navigate({
        search: "",
      });
      setOnDelete(false);
      toast.success("Screen deleted successfully");
    } catch (e) {
      toast.error("Failed to delete screen");
    }
  };

  const cancelHandler = () => {
    setOnDelete(false);
    navigate({
      search: "",
    });
  };

  return (
    <>
      <DeleteDialog
        show={onDelete}
        itemName="OnBoarding Screen"
        actionName="Delete"
        onCancel={cancelHandler}
        onConfirm={onDeleteHandler}
        isLoading={isDeleting}
      />
      <div className="group cursor-pointer h-32 w-full bg-linear flex justify-between items-center gap-2 p-3 rounded-md">
        <img
          src={banner.image}
          alt="banner"
          className=" w-32 h-full rounded-md"
        />
        <div>
          <h4 className="text-sm text-white font-medium font-poppins">
            {banner.title}
          </h4>
        </div>
        <div className="flex items-center justify-center gap-2 mb-3  opacity-0 group-hover:opacity-100">
          <button
            onClick={() => {
              navigate(banner.docId);
            }}
            title="Edit"
            className="bg-white text-primary font-semibold text-sm p-2.5 rounded-full mt-3"
          >
            <AiOutlineEdit />
          </button>

          <button
            onClick={() => {
              setOnDelete(true);
              const queryParams = new URLSearchParams();

              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              queryParams.set("bannerId", banner.docId as string);

              navigate({
                search: `?${queryParams.toString()}`,
              });
            }}
            title="Delete"
            className="bg-white text-primary font-semibold text-sm p-2.5 rounded-full mt-3"
          >
            <BiTrash />
          </button>
        </div>
      </div>
    </>
  );
};

export default BannerCard;
