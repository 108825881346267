import { GLAM_N_GO_API } from "../../apiSlice";

export const getOrderProductsApiSlice = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getOrderProducts: builder.query({
      query: (data) => ({
        url: `/orders/getOrderProducts/${data.id}`,
        providesTags: ["ORDER"],
      }),
    }),
  }),
});

export const { useGetOrderProductsQuery } = getOrderProductsApiSlice;
