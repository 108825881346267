import React from "react";
import ICON from "assets/icons/Ticket.svg";

const DashboardSummaryCard = ({
  title,
  value,
}: {
  title: string;
  value: number;
}) => {
  return (
    <div className="min-w-[200px] px-4 py-5 max-sm:w-full bg-white rounded-xl  drop-shadow-md hover:shadow-lg cursor-pointer flex items-center justify-between gap-4">
      <div>
        <h3 className="font-poppins font-medium text-sm capitalize">{title}</h3>
        <p className="font-poppins font-semibold text-2xl leading-9">{value}</p>
      </div>
      <div className=" p-4 rounded-xl bg-gradient-to-r from-[#e8f2c8] to-[#e8f2c8]">
        <img src={ICON} />
      </div>
    </div>
  );
};

export default DashboardSummaryCard;
