export const ORDER_STATUS = {
  NEW: "NEW",
  PENDING: "PENDING",
  CANCELED: "CANCELLED",
  COMPLETED: "COMPLETED",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  DECLINED: "DECLINED",
  REFUNDED: "REFUNDED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  PAYMENT_PENDING: "PAYMENT_PENDING",
};

export const ORDER_STAGES = [
  {
    value: "ordered",
    label: "ordered",
  },
  {
    value: "confirmed",
    label: "confirmed",
  },
  {
    value: "shipped",
    label: "Out For Delivery",
  },
  {
    value: "delivered",
    label: "delivered",
  },
];

export const CURRENT_VERSION = "1.0.0";

export const STATIC_PAGES = {
  TERMS_AND_CONDITIONS_PAGE: "TERMS_AND_CONDITIONS_PAGE",
  PRIVACY_POLICY_PAGE: "PRIVACY_POLICY_PAGE",
  ABOUT_US_PAGE: "ABOUT_US_PAGE",
  CONTACT_US_PAGE: "CONTACT_US_PAGE",
  FAQ_PAGE: "FAQ_PAGE",
  RETURN_POLICY_PAGE: "RETURN_POLICY_PAGE",
  SHIPPING_POLICY_PAGE: "SHIPPING_POLICY_PAGE",
  CANCELATION_POLICY_PAGE: "CANCELATION_POLICY_PAGE",
};

export const BANNERS = [
  {
    type: "category",
    categoryId: "1",
    bannerId: "CATEGORY_BANNER_1",
    imageUrl: "https://via.placeholder.com/150",
  },
  {
    type: "shop",
    shopId: "383",
    bannerId: "SHOP_BANNER_TOP",
    imageUrl: "https://via.placeholder.com/150",
  },
  {
    type: "product",
    productId: "1",
    bannerId: "PRODUCT_BANNER_1",
  },
  {
    type: "page",
    pageId: "1",
    bannerId: "PAGE_BANNER_1",
  },
];

export const ONBOARDING_SCREENS_COLORS = [
  "#E991AA",
  "#F2ACAD",
  "#EFB6D1",
  "#FFDAA2",
  "#DA5178",
  "#DB5759",
  "#F6D0E5",
  "#ECA0B6",
  "#EFB0C2",
  "#F2C0CE",
  "#F5CFDA",
  "#F8DFE6",
  "#FBEFF2",
  "#E99791",
  "#E99194",
  "#E9919F",
  "#E991B4",
  "#E991BF",
  "#E991C9",
  "#D4849B",
  "#FD9DB8",
  "#FF9EBA",
];

export const PRODUCT_TAGS = {
  WOMEN: "women",
  KIDS: "kids",
  MEN: "men",
};

// "KAWEMPEDIVISION"
// 2
// :
// "MAKINDYEDIVISION"
// 3
// :
// "NAKAWADIVISION"
// 4
// :
// "RUBAGADIVISION"

export const DELIVERY_REGIONS = [
  {
    value: "KAMPALACENTRALDIVISION",
    label: "Kampala Central Division",
  },
  {
    value: "KAWEMPEDIVISION",
    label: "Kawempe Division",
  },
  {
    value: "MAKINDYEDIVISION",
    label: "Makindye Division",
  },
  {
    value: "NAKAWADIVISION",
    label: "Nakawa Division",
  },
  {
    value: "RUBAGADIVISION",
    label: "Rubaga Division",
  },
];
