import { GLAM_N_GO_API } from "../../apiSlice";

export const getSocialApi = GLAM_N_GO_API.injectEndpoints({
  endpoints: (builder) => ({
    getSocial: builder.query({
      query: (data) => ({
        url: `/socials/${data.id}`,
        providesTags: ["SOCIALS"],
      }),
    }),
  }),
});

export const { useGetSocialQuery, useLazyGetSocialQuery } = getSocialApi;
