import {
  BreadCrumb,
  Button,
  DELIVERY_LOCATIONS_COLUMNS,
  DeliveryCostsForm,
  GGButton,
  GGTable,
  Modal,
  SectionTitle,
} from "components";

import { type DLocation } from "interfaces";
import React, { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { RiArrowDownLine, RiCheckboxCircleFill } from "react-icons/ri";
import { toast } from "react-toastify";
import {
  useDeleteDeliveryLocationMutation,
  useGetDeliveryRegionsQuery,
  useLazyGetDeliveryLocationsByRegionQuery,
} from "store/api";

const DeliveryCost = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<DLocation>();
  const [selectedRegions, setSelectedRegions] = useState<string[]>([
    "KAMPALA CENTRAL DIVISION",
  ]);
  const [locations, setLocations] = useState<Record<string, DLocation[]>>({});
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const {
    data,
    refetch: refetchRegions,
    isFetching: isFetchingRegions,
  } = useGetDeliveryRegionsQuery("deliveryRegions");
  const [getDeliveryLocations, { isFetching }] =
    useLazyGetDeliveryLocationsByRegionQuery();

  const [deleteDeliveryLocation, { isLoading: isDeleting }] =
    useDeleteDeliveryLocationMutation();

  const handleChangeRegion = (region: string, refetch?: boolean) => {
    if (isFetching) return toast.error("Please wait, fetching data...");
    if (locations[region] && !refetch) return;
    getDeliveryLocations(region)
      .unwrap()
      .then((sData) => {
        const locationsData = {
          ...locations,
          [region]: sData?.costs?.map((loc: any) => ({ ...loc, region })),
        };
        setLocations(locationsData);
      })
      .catch(() => {
        toast.error(`Failed to fetch data ${region}, Please try again`);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current === null) return;

      if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    handleChangeRegion("KAMPALA CENTRAL DIVISION");
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const mappedLocationData = Object.values(locations)
    .map((loc) => loc)
    .flat()
    ?.reverse();
  const filteredLocations = mappedLocationData?.filter((loc: DLocation) =>
    selectedRegions.includes(loc.region),
  );

  const deleteHandler = async () => {
    try {
      await deleteDeliveryLocation({
        id: selectedRegion?.docId,
        region: selectedRegion?.region,
      }).unwrap();

      await refetchRegions();
      setModalOpen(false);

      toast.success("Location deleted successfully");
    } catch (e) {
      toast.error("Failed to delete location");
    }
  };

  const modalContent = () => {
    if (isEdit) {
      return (
        <DeliveryCostsForm
          isEdit={isEdit}
          setAction={setIsEdit}
          selectedLocation={selectedRegion}
          regions={data?.costs}
          refetchData={refetchRegions}
        />
      );
    }
    if (isAdd) {
      return (
        <DeliveryCostsForm
          setAction={setIsAdd}
          regions={data?.costs}
          refetchData={refetchRegions}
        />
      );
    }
    return (
      <>
        <p className="text-sm">
          Are you sure you want to delete this location?, This action is
          permanent and cannot be undone.
        </p>

        <div className="flex justify-end gap-2">
          <GGButton
            type="button"
            variant="outlined"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </GGButton>
          <GGButton type="button" onClick={deleteHandler} loading={isDeleting}>
            Yes, Delete
          </GGButton>
        </div>
      </>
    );
  };

  return (
    <div>
      <BreadCrumb />
      <div className="card">
        <div className="w-full mt-8">
          <div className="flex items-center justify-between">
            <SectionTitle title="Delivery Locations & Costs" />
            <div className="flex items-center gap-2">
              <div className="relative flex justify-center" ref={menuRef}>
                <Button
                  variant="default"
                  type="button"
                  onClick={() => {
                    setShowMenu(!showMenu);
                  }}
                  title="Select Region"
                  className="cursor-pointer flex items-center justify-center"
                >
                  {selectedRegions.length > 0 ? (
                    <>
                      <div className="flex gap-1 items-center px-2 py-1 uppercase border font-bold text-xs text-navy/50 border-navy/10 w-fit bg-navy/10 rounded-full">
                        {selectedRegions[0]}
                      </div>
                      {selectedRegions.length > 1 && (
                        <div className="flex gap-1 items-center px-2 py-1 uppercase border font-bold text-xs text-navy/50 border-navy/10 w-fit bg-navy/10 rounded-full">
                          +{selectedRegions.length - 1}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex gap-1 items-center px-2 py-1 uppercase border font-bold text-xs text-navy/50 border-navy/10 w-fit bg-navy/10 rounded-full">
                      {data?.costs?.[0]}
                    </div>
                  )}

                  <RiArrowDownLine />
                </Button>
                {showMenu && (
                  <div
                    className={`flex flex-col justify-start gap-1 absolute bg-white rounded-sm  top-9 right-0 shadow-md ${showMenu ? "max-h-max px-3 py-2" : "max-h-0"} transition-all duration-75 ease-in-out overflow-hidden`}
                    style={{
                      zIndex: 1000,
                    }}
                  >
                    {data?.costs?.map((reg: string) => (
                      <div
                        key={reg}
                        title={reg}
                        onClick={async () => {
                          if (selectedRegions.includes(reg)) {
                            if (selectedRegions.length === 1) return;
                            setSelectedRegions(
                              selectedRegions.filter((r) => r !== reg),
                            );
                            return;
                          }
                          setSelectedRegions([...selectedRegions, reg]);
                          handleChangeRegion(reg);
                        }}
                        className={`flex justify-between items-center gap-2 cursor-pointer text-textColor2 hover:text-primary hover:bg-[aliceblue] w-full px-3 py-2 rounded-md`}
                      >
                        {reg}{" "}
                        {selectedRegions.includes(reg) && (
                          <RiCheckboxCircleFill className="text-primary" />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Button
                variant="default"
                title="Add Delivery Location"
                className="flex items-center justify-center "
                onClick={() => setIsAdd(true)}
              >
                <FaPlus className="text-xl" />
              </Button>
            </div>
          </div>
          <div className="mt-4">
            <Modal
              open={modalOpen || isEdit || isAdd}
              setOpen={setModalOpen || setIsEdit || setIsAdd}
              center
              body={
                <div className="flex flex-col gap-2">
                  <h3 className="text-lg font-semibold">
                    {isEdit
                      ? "Edit Delivery Location"
                      : isAdd
                        ? "Add Delivery Location"
                        : "Delete Delivery Location"}
                  </h3>

                  {modalContent()}
                </div>
              }
            />
            <GGTable
              data={filteredLocations ?? []}
              columns={DELIVERY_LOCATIONS_COLUMNS}
              isLoading={isFetching || isFetchingRegions}
              searchInputPlaceholder="Search Products"
              onEditHandler={(selectedItem: DLocation) => {
                setIsEdit(true);
                setSelectedRegion(selectedItem);
              }}
              onDeleteHandler={(selectedItem: DLocation) => {
                setModalOpen(true);
                setSelectedRegion(selectedItem);
              }}
              showExportButton
              onRefresh={() => {
                const regionToRefetch =
                  selectedRegions.length > 0
                    ? selectedRegions[selectedRegions.length - 1]
                    : "KAMPALA CENTRAL DIVISION";
                handleChangeRegion(regionToRefetch, true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryCost;
