import React from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "./store/slices/authSlice";
import {
  AdminDashboardPage,
  AuthenticationPage,
  DashboardPage,
  HelpPage,
  OrdersPage,
  ProductCategoriesPage,
  ProductsPage,
  ProfilePage,
  ReviewsPage,
  SettingsPage,
  StoresPage,
  SingleStore,
  UsersPage,
  SingleOrderPage,
  StaticPages,
  FaqsPage,
  PrivacyPolicyPage,
  SingleUserPage,
  AboutUsPage,
  ReturnsPage,
  DeliveryPage,
  TermsAndConditionsPage,
  CompanySocialsPage,
  OnBoardScreensPage,
  BannersPage,
  MyStore,
  DeliveryCosts,
  SubCategoryPage,
  AddNewUser,
  Media,
  NewUpload,
} from "pages";
import {
  AddBanner,
  AddCategory,
  AddFaq,
  AddOnBoardingScreen,
  AddProduct,
  AddShop,
  AddSocial,
  AddSubCategory,
  ChangePassword,
  ForgotPassword,
  LoginForm,
  RegisterForm,
} from "components";
import "./App.css";

function App() {
  const isAuthenticated = useSelector(selectIsAuthenticated) as boolean;

  return (
    <>
      <ToastContainer
        position="top-right"
        style={{ fontFamily: "Gilroy-Medium", fontSize: "16px" }}
      />
      <Routes>
        <Route path="/*" element={<AuthenticationPage />}>
          <Route index element={<Navigate to="/login" replace />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="register" element={<RegisterForm />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route
            path="reset-password/:passwordToken"
            element={<ChangePassword />}
          />
        </Route>
        <Route
          path="/dashboard/*"
          element={
            isAuthenticated ? (
              <DashboardPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        >
          <Route index element={<Navigate to="overview" replace />} />
          <Route path="overview" element={<AdminDashboardPage />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="orders/:orderId" element={<SingleOrderPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="products/new" element={<AddProduct />} />
          <Route path="products/:productId" element={<AddProduct isEdit />} />
          <Route
            path="product-categories"
            element={<ProductCategoriesPage />}
          />
          <Route path="product-categories/new" element={<AddCategory />} />
          <Route
            path="product-categories/:categoryId"
            element={<AddCategory isEdit />}
          />
          <Route path="sub-categories" element={<SubCategoryPage />} />
          <Route path="sub-categories/new" element={<AddSubCategory />} />
          <Route
            path="sub-categories/:catId"
            element={<AddSubCategory isEdit />}
          />
          <Route path="my-store" element={<MyStore />} />
          <Route path="my-store/:storeId/edit" element={<AddShop isEdit />} />
          <Route
            path="my-store/:storeId"
            element={<Navigate to="/dashboard/my-store" replace />}
          />
          <Route path="stores" element={<StoresPage />} />
          <Route path="stores/new" element={<AddShop />} />
          <Route path="stores/:storeId" element={<SingleStore />} />
          <Route path="stores/:storeId/edit" element={<AddShop isEdit />} />
          <Route path="reviews" element={<ReviewsPage />} />
          <Route path="help" element={<HelpPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="users/new" element={<AddNewUser />} />
          <Route path="users/:userId" element={<SingleUserPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="media" element={<Media />} />
          <Route path="media/upload" element={<NewUpload />} />
          <Route path="settings" element={<SettingsPage />} />

          <Route path="app-settings/*">
            <Route index element={<Navigate to="banners" replace />} />
            <Route path="banners" element={<BannersPage />} />
            <Route path="banners/add-banner" element={<AddBanner />} />
            <Route path="banners/:bannerId" element={<AddBanner isEdit />} />
            <Route path="static-pages" element={<StaticPages />} />
            <Route path="company-socials" element={<CompanySocialsPage />} />
            <Route path="company-socials/add-social" element={<AddSocial />} />
            <Route
              path="company-socials/:socialId"
              element={<AddSocial isEdit />}
            />

            <Route path="onboarding-screen" element={<OnBoardScreensPage />} />
            <Route path="delivery-costs" element={<DeliveryCosts />} />
            <Route
              path="onboarding-screen/add-onboard-screen"
              element={<AddOnBoardingScreen />}
            />
            <Route
              path="onboarding-screen/:screenId"
              element={<AddOnBoardingScreen isEdit />}
            />
            <Route path="faqs" element={<FaqsPage />} />
            <Route path="faqs/add-faq" element={<AddFaq />} />
            <Route path="faqs/:faqId" element={<AddFaq isEdit />} />
          </Route>
        </Route>
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route path="/delivery" element={<DeliveryPage />} />
        <Route path="/returns" element={<ReturnsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
    </>
  );
}

export default App;
