import React, { type FC, useEffect, useState } from "react";
import chroma from "chroma-js";
import Select, { type StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import { ImageUpload, Input } from "components/Common";
import { PRODUCT_TAGS } from "../../../constants";
import { FormControl } from "components/UI";
import { ProductColors, productSizes, shoeSizes } from "data/data";
import { useGetAllCategoriesQuery, useGetAllShopsQuery } from "store/api";
import {
  type FormComponentProps,
  type ColorOption,
  UserRoles,
} from "interfaces";
import { useSelector } from "react-redux";

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 3,
    marginLeft: 8,
    height: 10,
    width: 10,
  },
});

const ProductForm: FC<FormComponentProps> = ({
  values,
  setFieldValue,
  isEdit,
}) => {
  const { user } = useSelector((state: any) => state.auth);
  const { data: storeData } = useGetAllShopsQuery("shops");
  const { data: allCategories } = useGetAllCategoriesQuery("categories");
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const gallery = [
    {
      name: "gallery1",
      value: values.gallery1,
    },
    {
      name: "gallery2",
      value: values.gallery2,
    },
    {
      name: "gallery3",
      value: values.gallery3,
    },
  ];

  useEffect(() => {
    const shop = storeData?.stores?.find(
      (shopId: { docId: string }) => shopId.docId === values.storeId,
    ) as {
      categories: any[];
      shop: any[];
    };
    if (user?.role === UserRoles.Vendor) {
      const storeId = storeData?.stores?.[0]?.docId;
      setFieldValue("storeId", storeId);
    }
    setCategories(shop?.categories);
  }, [values.storeId, isEdit]);

  useEffect(() => {
    const subCategory = allCategories?.categories?.find(
      (category: { docId: string }) => category.docId === values.category,
    ) as { subCategories: any[] };

    setSubCategories(subCategory?.subCategories);
  }, [values.category, isEdit]);

  const colorStyles: StylesConfig<ColorOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.value);
      if (data.value === "#FFFFFF") {
        return {
          ...styles,
          ...dot(data.value),
          color: "#DD4470",
          backgroundColor: isDisabled
            ? undefined
            : isSelected
              ? data.value
              : isFocused
                ? color.alpha(0.1).css()
                : undefined,
          ":hover": {
            ...styles[":hover"],
            backgroundColor: "#eeeeee",
          },
        };
      }
      return {
        ...styles,
        ...dot(data.value),
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.value
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : data.value,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.value
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.value);
      if (data.value === "#FFFFFF") {
        return {
          ...dot(data.value),
          ...styles,
          backgroundColor: "#DD4470",
        };
      }
      return {
        ...dot(data.value),
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...dot(),
        ...styles,
        color: data.value,
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.value,
    }),
  };

  const Sizes =
    categories
      ?.find((category) => category.id === values.category)
      ?.name?.toLowerCase() === "shoes"
      ? shoeSizes
      : productSizes;

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
      <div>
        <div>
          <div className="grid grid-cols-1">
            <ImageUpload
              fieldName="mainImage"
              linkName="mainImageLink"
              setFieldValue={setFieldValue}
              note="required"
              label="Product Image"
              imageUrl={values.mainImage}
            />
          </div>
        </div>
        <div>
          <span className="block field-label mb-2.5">
            Product Gallery (Upload different variations of the product)
          </span>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            {gallery.map((item) => (
              <div className="mb-2.5" key={item.name}>
                <div className="grid grid-cols-1 gap-5">
                  <ImageUpload
                    fieldName={item.name}
                    setFieldValue={setFieldValue}
                    imageUrl={item.value}
                    linkName={`${item.name}Link`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <FormControl>
          <span className="block field-label mb-2.5">Color</span>
          <Select
            isMulti
            isSearchable
            name="colors"
            styles={colorStyles}
            value={values.colors}
            onChange={(selectedOption) => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              setFieldValue("colors", selectedOption);
            }}
            options={ProductColors}
            className="basic-multi-select "
            placeholder="Select Colors"
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary25: "#DD4470",
                primary: "#971B3F",
                primary50: "#DD4470",
              },
            })}
          />
        </FormControl>
        <FormControl>
          <span className=" field-label mb-2.5">Sizes</span>
          <span className=" field-label !text-[10px] ml-1 mb-2.5">
            (Type to search or create new size)
          </span>

          <CreatableSelect
            isMulti
            isSearchable
            name="sizes"
            value={values.sizes}
            onChange={(selectedOption) => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              setFieldValue("sizes", selectedOption);
            }}
            options={Sizes}
            className="basic-multi-select"
            placeholder="Select Sizes"
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary25: "#DD4470",
                primary: "#971B3F",
                primary50: "#DD4470",
              },
            })}
          />
        </FormControl>
      </div>
      <div>
        <Input type="text" name="name" label="Product Name" note="required" />
        <div>
          <Input
            name="description"
            label="Description"
            type="textarea"
            note="required"
          />
        </div>
        <Input
          name="storeId"
          type="select"
          label="Store"
          options={storeData?.stores?.map((store: any) => ({
            label: store.name,
            value: store.docId,
          }))}
          selectLabel="Store"
        />

        <div className="grid grid-cols-1 gap-y-4 gap-x-2 sm:grid-cols-2">
          <Input
            type="select"
            name="category"
            label="Category"
            selectLabel="Category"
            options={categories?.map((category) => ({
              label: category.name,
              value: category.id,
            }))}
            note={
              user?.role !== UserRoles.Admin ? "Contact Admin to add new" : ""
            }
          />
          <Input
            name="subCategory"
            type="select"
            label="Sub Category"
            selectLabel="Sub Category"
            options={subCategories?.map((subCategory) => ({
              label: subCategory.name,
              value: subCategory.docId,
            }))}
          />
        </div>
        <div className="grid grid-cols-1 gap-y-4 gap-x-2 sm:grid-cols-2">
          <Input
            name="stockStatus"
            type="select"
            label="Stock Status"
            selectLabel="Status"
            options={[
              {
                label: "In Stock",
                value: "inStock",
              },
              {
                label: "Out of Stock",
                value: "outOfStock",
              },
            ]}
          />
          <Input
            type="text"
            name="brandName"
            label="Brand Name"
            note="required"
          />
        </div>
        <div className="grid grid-cols-1 gap-y-4 gap-x-2 sm:grid-cols-2">
          <Input
            type="number"
            name="regularPrice"
            label="Regular Price"
            note="required"
            prepend="UGX"
            min={100}
          />
          <Input
            type="number"
            name="price"
            label="Sale Price"
            note="required"
            prepend="UGX"
            min={0}
          />
        </div>

        <div className="grid grid-cols-1 gap-y-4 gap-x-2 sm:grid-cols-2">
          <Input
            label="Quantity In Stock"
            type="number"
            name="quantityInStock"
            min={0}
          />
          <Input label="Unit" type="text" name="unit" />
        </div>
        <div className="grid grid-cols-1 gap-y-4 gap-x-2 sm:grid-cols-2">
          <Input
            type="textarea"
            name="keyFeatures"
            label="Key Features"
            note="write every key feature on a new line"
            rows={5}
          />
          <div className="flex flex-col gap-2">
            <Input
              type="text"
              name="mainMaterial"
              label="Main Material"
              note="e.g Polyester"
            />
            <Input
              type="number"
              min={0}
              step={0.1}
              name="productWeight"
              label="Product Weight"
              note="kg"
            />
          </div>
        </div>
        <Input
          label="Tag"
          type="select"
          selectLabel="Tag"
          note="Is this a product for Women, Men or Kids?"
          name="tag"
          options={[
            { label: "Women", value: PRODUCT_TAGS.WOMEN },
            { label: "Men", value: PRODUCT_TAGS.MEN },
            { label: "Kids", value: PRODUCT_TAGS.KIDS },
          ]}
        />
      </div>
    </div>
  );
};

export default ProductForm;
